var IviteOnly = {
	checkCode:function(){
		if(invite_only){
		IviteOnly.toggleForm(true);
		var code = $('#inviteNumber').val();
		if(code.trim() != ""){
		
			$.get(baseUrl+"/invite/check/"+code, function( data ) {
				
			  if(data.is_valid){
			  	IviteOnly.toggleForm(false);
                                $('.codeRegistration').hide();
			  }else{
			  	$('.inviteWrapCodeMsg').html("Кодът който сте въвели е невалиден!");
			  }
			});
		}else{
			$('.inviteWrapCodeMsg').html("Трябва да въведете код!");
		}
		}
	},

	toggleForm:function (attr){
			$('.fb-btn').attr("disabled",attr);
		  	$('#emailInput').attr("disabled",attr);
		  	$('#register_password').attr("disabled",attr);
		  	$('#agreeCheckBox').attr("disabled",attr);
		  	$('#registerButton').attr("disabled",attr);
	},

	checkCodeProfile:function(){

		var code = $('#inviteNumber').val();

                
		if(code.trim() != ""){
                        console.log(baseUrl);
			$.get(baseUrl+"/invite/checkUpdate/"+code,  function( data ) {
				
			  if(data.is_valid){
			  	$('.inviteWrapCodeMsg').html("Кодът бе потвърден успeшно!");
                               
			  }else{
			  	$('.inviteWrapCodeMsg').html("Кодът, който сте въвели е невалиден!");
			  }
			});
		}else{
			$('.inviteWrapCodeMsg').html("Трябва да въведете код!");
		}
	},
    checkCodeProfileModal:function(){
            var code = $('.inviteNumber').val();
                
		if(code.trim() != ""){
                        console.log(baseUrl);
			$.get(baseUrl+"/invite/checkUpdate/"+code,  function( data ) {
				
			  if(data.is_valid){
			  	$('.inviteWrapCodeMsg').html("Кодът бе потвърден успeшно!");
                                $('.inviteOnlyModal').hide();
                                $('.inviteOnlyOverlay').hide();
			  }else{
			  	$('.inviteWrapCodeMsg').html("Кодът, който сте въвели е невалиден!");
			  }
			});
		}else{
			$('.inviteWrapCodeMsg').html("Трябва да въведете код!");
		}
    },
    todoQuestionHover:function(){
    	$( ".todo-question" ).hover(
		  function() {
		    $(".todo-answer").show("slow");
		  }, function() {
		    $(".todo-answer").slideUp();
		  }
		);
    }
};
IviteOnly.todoQuestionHover();