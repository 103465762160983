function trans(key) {
	var txt = "";
	var loc = locale;

    try {
		if(key.startsWith('blockly.') && is_school == 0) {
			loc = 'en';
		}
        txt = eval(loc + key);
    } catch(err){
        txt = "";
    }
   return txt;
}

function loadLanguage(){
    var script = "<script type='text/javascript' src='"+baseUrl+'/mix-lang/'+lang+'/lang_mix.js'+"'></script>";
    setTimeout(function(){
       $('body').append(script);
        setSpeed();
    },1000)

}