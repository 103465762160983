/**
 *
 * user uuid
 */
var uuid=null;

var CacheProvider = (function(){
    function CacheProvider(){
        if(is_cache != ""  ){
            if(current_page == "home"){
             this.home();
             showStartPopUp();
            }else if(current_page == "courses"){
                this.courses();
            }else if(current_page == "paths"){
                this.paths();
            }else if(current_page == "path"){
                this.pathsStructure();
            }else if(current_page == "course"){
                this.courseStructure();
            }else if(current_page == "programming-kids"){
                this.courses();
            }else if(current_page == "bill"){
                this.bill();
            }else if(current_page == "register" || current_page == "invite" || current_page == "free-course"){
                this.register();
            }else if(current_page == "login"){
                this.login();
            }else if(current_page == "profile"){
                this.profile();
            }else if(current_page == "exercise"){
                this.exercise();
            }else if(current_page == "endOfCourse"){
                this.endCourse();
            }else if(current_page == "epay-bill"){
              get("cache/home",{},function(data){
                    avatarData(data);
                    token(data);
              });
            }else if(current_page == "school-course-create" ||
                    current_page == "school-course-view" ||
                    current_page == "add-group" ||
                    current_page == "contacts" || current_page == "about"){
                this.schoolCoursePages();
            }else if(current_page == "parents"){
                this.parents();
            }
        }else{
            /*for debug mode view all course*/
             $('.courseTile').show();
             $('.coursePathList').show();
        }


    }

    function showConfirmEmailModal(data){
        var today = new Date();


        if(localStorage.getItem("show_activate_modal_time") == null){

            if(data.confirm_email == false){
                var twentyMinutesLater = new Date();
                twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 30);
                localStorage.setItem("show_activate_modal_time",twentyMinutesLater);
                $('#confirmEmailOverlay').show();
                $('.confirmAccount').show();

            }
        }

        if(localStorage.getItem("show_activate_modal_time") != null ){

            var showTime = new Date(localStorage.getItem("show_activate_modal_time"));

            if(today > showTime){
                if(data.confirm_email == false){
                    $('#confirmEmailOverlay').show();
                    $('.confirmAccount').show();
                    $('.closeConfirmAccount').hide();
                    $('.later30').hide();
                }
            }
        }
    }
    function showFunctionsStopping(data){
        if(data.stop_functions[0]){
            $('.stopFunctionalities').show();
            $('.stopFunctionalitiesOverlay').show();
            $('#hours-start').html(data.stop_functions[1]);
            $('#hours-finish').html(data.stop_functions[2]);
        }
    }
    var params = window.location.href.split("/");
    var id = params[params.length-1];

    function get(route, params,callback){
        params['registrationId'] = localStorage.registrationId;
        $.get(baseUrl+"/"+route, params )
        .done(function( data ) {
             data = JSON.parse(data);
             /**
              * set user uuid if is logged
              */
             if(typeof data.uuid != "undefined"){
                 uuid = data.uuid;
             }
             is_tester = data.is_tester;
             isSubscribe(data);
             showConfirmEmailModal(data);
             if(current_page != "exercise"){
                showPaymentMethods(data);
             }
             user_is_paid = data.user_is_paid;
             registrationId = data.registrationId;
             showGratisMessage(data);
             showStopSite(data);
             showFunctionsStopping(data);
             showCoursesForTeachers(data);
             /*showActivationForCardUsers(data);*/
             //set logout token
             $('#logout_token').val(data.token);
             showAskingTelephone(data);
             showPromotionWindow(data);
             setMsgCount(data);
             showMultiplayerStuff(data);
             if(data !== "")
             callback(data);
        });
    }
    function showGratisMessage(data){
        if(data.is_gratis){
            $('#gratis_message').slideDown();
        }
    }
    function showPaymentMethods(data){
      var payments = data.payments;
      if(typeof payments == "undefined"){
        return false;
      }
      if(payments[0].active == 1){
          $('#checkout').show();
      }else{
        $('#checkout').hide();
      }

      if(payments[1].active == 1){
          $('#epay_cards').show();
      }else{
        $('#epay_cards').hide();
      }
      if(payments[2].active == 1){
          $('#epay').show();
      }else{
          $('#epay').hide();
      }

      if(payments[3].active == 1){
          $('#easy_pay_office').show();
      }else{
          $('#easy_pay_office').hide();
      }

      if(payments[4].active == 1){
          $('#econt').show();
      }else{
          $('#econt').hide();
      }

      if(payments[5].active == 1){
          $('#bank_transfer').show();
      }else{
        $('#bank_transfer').hide();
      }

      if(data.role == "teacher"){
        $('#paypal').show();
      }
    }
    function showPromotionWindow(data){
        if(current_page != "exercise" && current_page != "free-course"){
            if((data.finish_ex_count == null || data.finish_ex_count > 4) && (user_is_paid == null || typeof user_is_paid == "undefined")){
                if(getCookie("id") == ""){
                    $('#backToSchoolModal').fadeIn();
                    $('.backToSchoolOverlay').fadeIn();
                    $('.closeModalFromBtn').addClass('inactive');
                    $('.closeModalSchool').removeClass('inactive');

                }
                $('.promotionBtnFixed').removeClass('inactive');
            }
        }
    }
    function showMultiplayerStuff(data){
        if(data.allowMultiplayer == 1){
            if(data.friendsCount == 0){
                $('.inviteFriends').removeClass('inactive');
            }else if(data.friendsCount == 0){
                $('.inviteMore').removeClass('inactive');
            }

                $('.subscription-btn#activateCode').show();
                $('.swords').attr('style','display:inline-block !important');

            if(is_user){
                $('.textCopy').html(baseUrl+'/invite/'+data.user_uuid);
                $('.whatsapp').attr('href','https://api.whatsapp.com/send?text='+baseUrl+'/invite/'+data.user_uuid);
            }


        }
    }
    function showCoursesForTeachers(data){
        if(data.is_teacher_tester == 1){
          if(current_page != "course"){
            $('.teacherTestAvailableCourses').removeClass('inactive');
            $('.navigationTeachers').removeClass('inactive');
          }else{
            $('.navigationTeachers').removeClass('inactive');
            $('.showTeacherCourses').show();
          }
        }
    }
    function showActivationForCardUsers(data){
        /*if(data.is_card_user == 1 && data.is_card_user_active == 0){
            $('.activeScratchAccount').fadeIn();
            $('.activeScratchAccountOverlay').fadeIn();
        }else{
            $('.activeScratchAccount').fadeOut();
            $('.activeScratchAccountOverlay').fadeOut();
        }*/
        if(data.confirm_email && data.is_card_user_active == 0 && data.role == "student" && sessionStorage.getItem('no-scratch') == null){
            $('.activeScratchAccount').fadeIn();
            $('.activeScratchAccountOverlay').fadeIn();
        }else{
            $('.activeScratchAccount').fadeOut();
            $('.activeScratchAccountOverlay').fadeOut();
        }
    }
    function showAskingTelephone(data){
        var cooks = document.cookie;

        if(data.telephone == "" && data.telephone != null /*&& data.counter_tel <= 3 && data.counter_tel != null && getCookie("email") == ""*/){
            /*$('.askingForTelephone').removeClass('inactive');
            $('.askingForTelephoneOverlay').removeClass('inactive');
            var d = new Date();
            d.setTime(d.getTime() + (3*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = "email="+data.email+"; "+expires;*/
        }
    }
   function setMsgCount(data){
       if(typeof user_role != "undefined" && user_role == "student"){
           $('#msg-counter').html(data.msg_count);
       }
   }
    function showStartPopUp(){
        setTimeout(function(){
            if(!$("#startTrialOverlay").is(":visible") &&
                    !$("#overlayApproveTrial").is(":visible") && $('#subscribeIcon').is(":visible")
                    && !$('.customer_service_overlay').is(":visible")){
                $('.start-popup').slideDown(200);
                $('.start-popup-modal').slideDown(200);
            }
        },60000);

        if(getParameterByName("subscribe",window.location.href) == "true"){
            $('.approve-popup').show();
            setTimeout(function(){
                $('.approve-popup-modal').show();
                $('.approve-popup-modal h1').text("Ти потвърди акаунта си успешно!");
                if(!mobile){
                    $('.approve-popup-modal h1').css({display:'inline-block',position:'absolute',top:'35%',left:'25%'});
                }
                $('.approve-popup-modal').find('.roket-pop').removeClass('fa-rocket').addClass('fa-check-square-o');
                $('#approve-popup-close').click(function(){
                    $('.approve-popup').hide();
                });
            },3);
        }
    }

    function assertPageIsLoaded(){
       var color = getStyleRuleValue('color', '.sandwich');

        if($('#username').css('color') == 'rgb(0, 153, 153)' && $('#testButton').css('color') == 'rgb(255, 255, 255)' && $('.sandwich').length != 0  && ($('.sandwich').css('color') == 'rgb(0, 153, 153)' || $('.sandwich').css('color')  == 'rgb(255, 255, 255)')){
          $('.caching-problem').addClass('inactive');
        }else{
            $('.caching-problem').removeClass('inactive');
          $.ajaxSetup({
             headers: {
                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
             }
          });
         $.post( baseUrl+ '/cache/recache', {publicId: public_id })
             .done(function() {
               window.location.reload();
             });
        }
    }

    function avatarData(data){
            user_role = data.role;
            is_user = data.is_user;
            if(is_user){
                var name;
                var firstName;
                if(data.username){
                    name = (data.username).split(' ');
                    firstName = name[0];
                }else{
                    firstName = data.email;
                }
                $('#username .textColor').empty();
                if(data.role == "student"){
                    $('.userStatus').html(trans("langPage.roleStudent"));
                    $('#toNewCourse').remove();
                }else if(data.role == "teacher"){
                    $('.userStatus').html(trans("langPage.roleTeacher"));

                }
                $('#username .textColor').html(firstName);

                if(data.avatar == false || data.avatar == null){
                    $('#user_icons').empty();
                    $('#user_icons').html('<span class="fa fa-user"></span>');
                }else{
                    $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
                }
                $('#toLogin').remove();
                $('#toRegister').remove();

            }
    }
    function coursesPercent(data){
        var courses = data["course_percent"];
        var firstEx = data["first_exercise_of_courses"];
        var startedEx = data["active_exercises"];
        if(is_user){
            /*if(data["course_percent"].length == 0){
                    for(var fe in firstEx){
                        $('.courseTile'+fe).find('.percentageDone .profileCoursePercent').html("0%");
                        $('.courseTile'+fe).find('.percentageDone .profileProgressFront').attr('style',"width:0%;");
                        $('.courseTile'+fe).find('.startCourseButton .courseStartButton').html('Старт');
                        $('.courseTile'+fe).find('.startCourseButton').attr('href','exercise/view/'+firstEx[fe].public_id);
                        console.log(firstEx[fe].public_id);
                    }
            }else{
                courses = data["course_percent"];
                for(var co in courses){
                    var user_data = courses[co];
                    $('.percentageDone_'+co).find('.profileCoursePercent').html(user_data.progress + "%");
                    $('.percentageDone_'+co).find('.profileProgressFront').attr('style','width:'+user_data.progress + "%;");
                    $('.courseTile'+co).find('.startCourseButton .courseStartButton').html('Продължи курса');
                    $('.courseTile'+co).find('.startCourseButton').attr('href','exercise/view/'+startedEx[co].public_id);
                }
                for(var fe in firstEx){
                    for(var co in courses){
                        if(fe != co){
                            $('.courseTile'+fe).find('.percentageDone .profileCoursePercent').html("0%");
                            $('.courseTile'+fe).find('.percentageDone .profileProgressFront').attr('style',"width:0%;");
                            $('.courseTile'+fe).find('.startCourseButton .courseStartButton').html('Старт');
                            $('.courseTile'+fe).find('.startCourseButton').attr('href','exercise/view/'+firstEx[fe].public_id);
                            console.log(firstEx[fe].public_id);
                        }
                    }
                }
            }*/
            if(is_user){
                if(data["course_percent"].length == 0){
                        $('.percentageDone .profileCoursePercent').html("0%");
                        $('.percentageDone .profileProgressFront').attr('style',"width:0%;");
                        if(current_page == "courses"){
                            $('.startCourseButton .courseStartButton').html('Старт');
                            for(var fe in firstEx){
                                $('.courseTile'+fe).find('.startCourseButton').attr('href','exercise/view/'+firstEx[fe].public_id);
                            }
                        }

                }else{
                    //$('.percentageDone_'+data["course_percent"].progress);
                    courses = data["course_percent"];
                    $('.percentageDone .profileCoursePercent').html("0%");
                    $('.percentageDone .profileProgressFront').attr('style',"width:0%;");
                    $('.startCourseButton .courseStartButton').html('Старт');
                    for(var co in courses){
                        var user_data = courses[co];

                        $('.percentageDone_'+co).find('.profileCoursePercent').html(user_data.progress + "%");
                        $('.percentageDone_'+co).find('.profileProgressFront').attr('style','width:'+user_data.progress + "%;");
                        if(current_page == "courses"){
                            if(user_data.progress < 100){
                                $('.courseTile'+co).find('.startCourseButton .courseStartButton').html('Продължи');
                            }else{
                                 $('.courseTile'+co).find('.startCourseButton .courseStartButton').html('КУРСЪТ Е ЗАВЪРШЕН');
                            }
                            //console.log(co);
                            if(startedEx.length != 0 && typeof startedEx[co] != "undefined"){
                                $('.courseTile'+co).find('.startCourseButton').attr('href','exercise/view/'+startedEx[co].public_id);
                            }

                        }

                        /*console.log(startedEx[co].public_id);*/
                    }
                }
            }
            if(current_page == "courses"){
                var message;
                var href;
                if(data["first_exercise_of_courses"].length != 0){

                    for(var start in startedEx){


                    }
                    for(var co in courses){
                       /* var user_data = courses[co];
                        var course_id = courses[co].course_id;
                        if(user_data.progress == 0){
                            message = trans('langPage.start-course');
                            $('.courseTile'+course_id).find('.startCourseButton').attr('href','exercise/view/'+link);
                        }else if(user_data.progress < 100){
                            $('.courseTile'+course_id).find('.startCourseButton .courseStartButton').html('Продължи');
                            message = trans('langPage.continue-course');
                            href = trans();
                        }else if(user_data.progress >= 100){
                            message = trans('langPage.finished-course');
                            href = trans();
                        }*/
                    }
                    for(var first_ex in firstEx){
                        /*var link = firstEx[first_ex].public_id;
                        var course_id = firstEx[first_ex].cours_id;

                        $('.courseTile'+course_id).find('.startCourseButton').removeAttr('href');
                        $('.courseTile'+course_id).find('.startCourseButton').attr('href','exercise/view/'+link);
                        $('.courseTile'+course_id).find('.startCourseButton .courseStartButton').html('Старт');*/
                    }

                }
            }

        }
        /*show hidden school courses for user*/
        showUserSchoolCourse(data.school_courses,data.role);
    }
    function coursesPercentInPaths(data){
        var courses = data["course_percents"];
        var firstEx = data["first_exercise_of_courses"];
        var startedEx = data["active_exercise_by_course"];
        if(is_user){

            if(is_user){
                if(data["course_percents"].length == 0){
                        $('.percentageDone .profileCoursePercent').html("0%");
                        $('.percentageDone .profileProgressFront').attr('style',"width:0%;");

                            $('.startCourseButton .courseStartButton').html('Старт');
                            for(var fe in firstEx){
                                $('.courseTile'+fe).find('.startCourseButton').attr('href','exercise/view/'+firstEx[fe].public_id);
                            }


                }else{
                    //$('.percentageDone_'+data["course_percent"].progress);
                    courses = data["course_percents"];
                    $('.percentageDone .profileCoursePercent').html("0%");
                    $('.percentageDone .profileProgressFront').attr('style',"width:0%;");
                    $('.startCourseButton .courseStartButton').html('Старт');
                    for(var co in courses){
                        var user_data = courses[co];

                        $('.percentageDone_'+co).find('.profileCoursePercent').html(user_data.progress + "%");
                        $('.percentageDone_'+co).find('.profileProgressFront').attr('style','width:'+user_data.progress + "%;");

                            if(user_data.progress < 100){
                                $('.courseTile'+co).find('.startCourseButton .courseStartButton').html('Продължи');
                            }else{
                                 $('.courseTile'+co).find('.startCourseButton .courseStartButton').html('КУРСЪТ Е ЗАВЪРШЕН');
                            }
                            //console.log(co);
                            if(startedEx.length != 0 && typeof startedEx[co] != "undefined"){
                                $('.courseTile'+co).find('.startCourseButton').attr('href','exercise/view/'+startedEx[co].public_id);
                            }



                        /*console.log(startedEx[co].public_id);*/
                    }
                }
            }
            if(current_page == "courses"){
                var message;
                var href;
                if(data["first_exercise_of_courses"].length != 0){

                    for(var start in startedEx){


                    }
                    for(var co in courses){
                       /* var user_data = courses[co];
                        var course_id = courses[co].course_id;
                        if(user_data.progress == 0){
                            message = trans('langPage.start-course');
                            $('.courseTile'+course_id).find('.startCourseButton').attr('href','exercise/view/'+link);
                        }else if(user_data.progress < 100){
                            $('.courseTile'+course_id).find('.startCourseButton .courseStartButton').html('Продължи');
                            message = trans('langPage.continue-course');
                            href = trans();
                        }else if(user_data.progress >= 100){
                            message = trans('langPage.finished-course');
                            href = trans();
                        }*/
                    }
                    for(var first_ex in firstEx){
                        /*var link = firstEx[first_ex].public_id;
                        var course_id = firstEx[first_ex].cours_id;

                        $('.courseTile'+course_id).find('.startCourseButton').removeAttr('href');
                        $('.courseTile'+course_id).find('.startCourseButton').attr('href','exercise/view/'+link);
                        $('.courseTile'+course_id).find('.startCourseButton .courseStartButton').html('Старт');*/
                    }

                }
            }

        }
        /*show hidden school courses for user*/
        showUserSchoolCourse(data.school_courses,data.role);
    }
    function coursePicturesStructure(){

    }
    function isMobile(){
        if(data.isMobile == 1){
            //$('.mobileInclude').removeClass('inactive');
        }
    }
    function profileAvatar(data){
         if(is_user){
            if(data.avatar == false || data.avatar == null){
                   $('#uploadAvatar').empty();
                   $('#uploadAvatar').html('<div class="fa fa-user" id="user_profile_icon"></div>');
            }else{
                   $('#uploadAvatar').html('<img id="profilePicture" src="'+data.avatar+'" width="80px;" height="auto" style="width:80px; height:80px;">');
            }
            if(data.username != null){
                $('.prof_text').find('.bolder').html(data.username);
            }else{
                $('.prof_text').find('.bolder').html(data.email);
            }

            if((data.role) == "student"){
                $('.prof_text h3').html(trans("langPage.roleStudent"));
            }else if((data.role)=="teacher"){
                $('.prof_text h3').html(trans("langPage.roleTeacher"));
            }

            /*if(data["lastActiveExercise"] == false){
                console.log("TUK");

                $('.textSize-smallTitle').html("Нека да започнем с курса");
                $('.current_courseTitle').html('Програмиране: първи стъпки');

                $('.current_courseTitle').empty();

                $('.continueWrapper').find('.mainCourseProgress').remove();
                $('.current_courseTitle').html('Програмиране: първи стъпки');
                $('.continueWrapper').find("a").attr('href',baseUrl + "/exercise/view/abceaf2c-e211-11e6-bf79-062c03d014e1");
                $('.continueWrapper').find("#continueButton").html("Започни курсa" + '<span class="fa fa-angle-double-right"></span>');
                 $('.current_courseTitle').append("<div class='first_course_logo logo_course1'></div>");
                 $('.current_courseTitle').append('<a href="'+baseUrl+'/exercise/view/abceaf2c-e211-11e6-bf79-062c03d014e1"><div id="continueButton" class="button prColBG accColBGHoverSame tileShadowPage current_course continueButtonWithStart">Започни курса<span class="cB"></span> <span class="fa fa-angle-double-right"></span></div> </a>');
            }else{
                $('.textSize-smallTitle').html("Последният курс в който имате активност е");
                $('.current_courseTitle').empty();
                $('.current_courseTitle').html(data["lastActiveExercise"].course_name);
                if(data["course_percent"].length == 0){
                    $('.continueWrapper').find('.rightOverBar').html("0%");
                    $('.continueWrapper').find('.profileProgressFront').attr('style','width:0%;');
                }else if(data["course_percent"][data["lastActiveExercise"]['course_id']].progress == 100){
                    $('#continueButton').addClass('inactiveButton');
                    $('#continueButton').removeClass('prColBG accColBGHoverSame');
                    $('#continueButton').text("Курсът е завършен");
                    $('.continueWrapper').find('.rightOverBar').html("100%");
                    $('.continueWrapper').find('.profileProgressFront').attr('style','width:100%;');
                }else{
                    $('#continueButton').removeClass('inactiveButton');
                    $('#continueButton').addClass('prColBG accColBGHoverSame');
                    $('#continueButton').text("Продължи");
                    $('.continueWrapper').find('.rightOverBar').html(data["course_percent"][data["lastActiveExercise"].course_id].progress + "%");
                    $('.continueWrapper').find('.profileProgressFront').attr('style','width:' + data["course_percent"][data["lastActiveExercise"].course_id].progress+"%"+';');
                }

                $('.continueWrapper').find("a").removeAttr('href');
                if(data["course_percent"][data["lastActiveExercise"].course_id].progress >= 100){
                      $('.continueWrapper').find("cB").empty().html('Завършен').addClass('inactiveButton');
                      $('.continueWrapper').find("a").attr('href',"#");
                }else{
                    $('.continueWrapper').find("cB").empty().html('Продължи');
                    $('.continueWrapper').find("a").attr('href',baseUrl + "/exercise/view/"+data["lastActiveExercise"].next_exercise);
                }






            }*/
         }

    }
    function token(data){
       $("input[name='_token']").val(data["token"]);
        $("meta[name='csrf-token']").attr("content",data["token"]);

    }
    function errorMsg(data){
        if(data["captcha"] != ""){
            $('body').append('<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?hl='+locale+'"></script>');
            $('#capcha-group').empty();
            $('#capcha-group').prepend(data["captcha"]);
        }else{
            $('#capcha-group').empty();
        }
        if(data.msg != null){
            $('#capcha-group').append(data.msg);
        }
        if(data.error != null){
             $('.errorMsg').find('ul').append('<li>'+data.error+'</li>');
        }
        if(data.errors != null){
             $('.errorMsg').find('ul').append('<li>'+data.errors["msg"]+'</li>');
        }
    }

    function errorMsgRegister(data){
        if(data["captcha"] != ""){
           $('body').append('<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?hl='+locale+'"></script>');
            $('#capcha-group').empty();
            $('#capcha-group').prepend(data["captcha"]);
        }else{
            /*$('#capcha-group').empty();*/
        }
        if(data.msg != null){
            $('#capcha-group-msg').append(data.msg);
            if(typeof grecaptcha != "undefined"){
                grecaptcha.render(
            "capcha-group",
                {sitekey:"6LfyGiEUAAAAAGpNv6bk4bLdqGEeDhmLdMchQxWj"}
            );
            }

        }

        if(typeof data.invite_code != "undefined" && data.invite_code != null){
            IviteOnly.toggleForm(false);
            $('#inviteNumber').val(data.invite_code);
            $('.codeRegistration').hide();
        }
       /* for(var msg in data.error){
            var er = data.error;
            console.log(er[msg]);
            if(typeof er[msg] != "object"){
                 $('.errorMsg').find('ul').append('<li>'+er[msg]+'</li>');
            }


            if(current_page == "login"){
                $('#login_mail').val(er["email"].email);
            }
        }*/

        if(data["error"] != null){
            if(data["error"].email != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].email[0]+'</li>');
            }
            if(data["error"].password != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].password[0]+'</li>');
            }
            if(data["error"].phone != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].phone[0]+'</li>');
            }
            if(data["error"].code != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].code[0]+'</li>');
            }
            if(invite_only == 1){
                /*IviteOnly.toggleForm(true);
		var code = $('#inviteNumber').val();
		if(code.trim() != ""){

			$.get(baseUrl+"/invite/check/"+code, function( data ) {

			  if(data.is_valid){
			  	IviteOnly.toggleForm(false);
                                 $('.codeRegistration').hide();
			  }else{
			  	$('.inviteWrapCodeMsg').html("Кода който сте въвели е невалиден!");
			  }
			});
		}else{
			$('.inviteWrapCodeMsg').html("Трябва да въведете код!");
		}*/
                $('.codeRegistration').hide();
                IviteOnly.toggleForm(false);
            }
        }
    }
    function errorMsgInHome(data){
        if(data["captcha"] != "" && data["captcha"] != undefined){
            $('body').append('<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?hl='+locale+'"></script>');
            $('#capcha-group').empty();
            $('#capcha-group').prepend(data["captcha"]);
        }else{
            $('#capcha-group').empty();
        }
        if(data.msg != null){
            $('#capcha-group').append(data.msg);
        }
        if(data["error"] != null){
            if(data["error"].email != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].email[0]+'</li>');
            }
            if(data["error"].password != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].password[0]+'</li>');
            }
        }

    }
    function avatarInEx(data){
        var name;
        var firstName;
        if(data.username){
            name = (data.username).split(' ');
            firstName = name[0];
        }else{
            firstName = data.email;
        }
        $('#username .textColor').empty();
        if(data.role == "student"){
            $('.userStatus').html(trans("langPage.roleStudent"));
            $('#toNewCourse').remove();
        }else if(data.role == "teacher"){
            $('.userStatus').html(trans("langPage.roleTeacher"));

        }
        $('#username .textColor').html(firstName);
       /* if(data.username != null){

        }else{
             $('#username .textColor').html(data.email);
        }*/
        if(data.avatar == false || data.avatar == null){
            $('#user_icons').empty();
            $('#user_icons').html('<span class="fa fa-user"></span>');
        }else{
            $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
        }
    }
    function exHeader(data){
        if(isJunior){
            $('.myMoney').html(data.wallet);
            $('.myRuby').html(data.rubies);
            //$('.exN').empty();
            //$('.ex')
        }

        $('#logout_token').val(data.token);

    }
    function firstEnterEx(data){

        first_enter_course = JSON.parse(data.first_enter_course);
        if(!first_enter_course){
            $('.onboardingExplanation').hide();
            $('.ex_hand').hide();
            $('.popupOnboarding').hide();
            $('.mobilePopupOnboarding').hide();
            $('.popupOnboardingCode').hide();
            $('.popupOnboardingStaticCode').hide();
            $('.popupOnboardingStatic').hide();
            $('.infoHelpBox').hide();
            $('.circle-onboarding-parent').hide();
            $('#overlayInfoHelpBox').hide();
            $('#editorCont').css({zIndex:'auto'});
            setTimeout(function(){
                $('#overlayInfoHelpBox').hide();
            },1200);
            $('canvas.highlight').hide();
        }


    }
    function assignVariables(data){
        firstEnter = data.firstEnter;
        userRole = data.role;
        experience = data.experience;
        afterRules = data.afterRule, runtimeRules = data.runtimeRule, afterTestRules = data.afterTestRule;
        afterRules = JSON.parse(afterRules);
        runtimeRules = JSON.parse(runtimeRules);
        afterTestRules = JSON.parse(afterTestRules);
        isLastCourse = data.last_course_ex,isLastModule = data.last;
       /* isRelatedWithMasteryLocal = data.has_relation;
        is_emh = data.is_emh;*/
        currentLevel = data.level;
        userId = data.user_id;
        coinWallet = data.wallet;

        countOfUsHints = data.countOfHints;
        nextExerciseId = data.nextExId;
        nextFinishedExId = data.next_finished_ex_id;
        nextFinishedExPublicId = data.next_finished_ex_public_id;
        nextExFromCourseId = data.next_ex_id;
        nextExFromCoursePublicId = data.next_ex_public_id;
        isFinishedBefore =data.is_finished_before;
		lastCourseExerciseId = data.last_course_ex;
		isSchool = data.is_school;

    }
    function assignVariablesJunior(data){
        firstEnter = data.firstEnter;
        userRole = data.role;
        experience = data.experience;
        isLastCourse = data.last_course_ex,isLastModule = data.last;
       /* isRelatedWithMasteryLocal = data.has_relation;
        is_emh = data.is_emh;*/
        currentLevel = data.level;
        userId = data.user_id;
        coinWallet = data.wallet;
        wallet_rubies = data.rubies;
        countOfUsHints = data.countOfHints;
        nextExerciseId = data.nextExId;
        nextFinishedExId = data.next_finished_ex_id;
        nextFinishedExPublicId = data.next_finished_ex_public_id;
        nextExFromCourseId = data.next_ex_id;
        nextExFromCoursePublicId = data.next_ex_public_id;
        isFinishedBefore =data.is_finished_before;
        lastCourseExerciseId = data.last_course_ex;
        userEmail = data.email;
		isSchool = data.is_school;
        /*if(redactor_type == "code_block"){
            if(data.user_editor_mode != null){
                editorMode = data.user_editor_mode;
                $('.chooseRedactorType').hide();
                $('.chooseRedactorTypeOverlay').hide();
            }else{
                editorMode = "block";
                $('.chooseRedactorType').show();
                $('.chooseRedactorTypeOverlay').show();
            }
            console.log(data.is_start_first);
            setTimeout(function(){
                if(editorMode == "code"){
                    if(!mobile){
                         toCodeEditorCache();
                    }else{
                        editorChange('toCode');
                    }
                }else if(editorMode == "block"){
                    if(!mobile){
                        toBlockEditorCache();
                    }else{
                        editorChange('toBlock');
                    }
                }
            },1200);
        }    */

        isExReady = true;

    }
    function changeUserEditor(data){
        if(redactor_type == "code_block"){
            if(data.user_editor_mode != null){
                editorMode = data.user_editor_mode;
                $('.chooseRedactorType').hide();
                $('.chooseRedactorTypeOverlay').hide();
            }else{
                editorMode = "block";
                $('.chooseRedactorType').show();
                $('.chooseRedactorTypeOverlay').show();
            }
            setTimeout(function(){
                if(editorMode == "code"){
                    if(!mobile){
                         toCodeEditorCache();
                    }else{
                        editorChange('toCode');
                    }
                }else if(editorMode == "block"){
                    if(!mobile){
                        toBlockEditorCache();
                    }else{
                        editorChange('toBlock');
                    }
                }
            },1200);
        }
    }
    function setExToken(data){
        $('meta[name="csrf-token"]').attr('content',data.token);
    }
    function userCode(data){
        if(data.restriction != null){

              if((data.course_for_teachers == 0 && data.is_teacher_tester == 0) || (data.course_for_teachers == 0 && data.is_teacher_tester == 1) || data.is_school == 0){
                  $('#restriction_window').show();
              }
        }

        if(isJunior){
            if(data.code != null){
            exerciseFuncJS = data.code;
            }else{
                exerciseFuncJS = "";
            }
            exerciseFuncJS = decodeURI(exerciseFuncJS);
            codeUser = decodeURI(data.code).replace('+', ' ');
            exerciseFuncHTML  = data.html_code;
            exerciseFuncHTML = decodeURIComponent(exerciseFuncHTML);
            if(typeof inventories !== "undefined" && redactor_type === "code"){

                inventories = JSON.parse(data.inventories);
                if(typeof data.finished_exercise.current_inventory != "undefined"){
                    finishedInventrory = JSON.parse(data.finished_exercise.current_inventory);
                    if(is_school == 1){
                        game_stage_level = data.finished_exercise.level_json;
                        gb.dispacher();
                    }
                }
            }
            if(redactor_type == "block" || redactor_type == "code" ||  redactor_type == "code_block"){
                if(is_school == 1){
                    game_stage_level = data.finished_exercise.level_json;
                    gb.dispacher();
                }
            }

            /*if(isFinishedBefore === 1){

                renderUserCode();

                var editor = ace.edit("myEditor");
                editor.setValue(exerciseFuncJS);
            }*/
            setTimeout(function(){
                if(redactor_type == "code_block"){
                    if(isFinishedBefore == 1){
                       renderUserCode();
                    }
                 }else{
                    if(is_math === 0){
                        var int = setInterval(function(){
                            if(typeof ace != "undefined"){
                                workFuncForExPractise.displayExerciseCode(exerciseFuncJS, language);
                                clearInterval(int);
                            }
                        },1000);
                    }
                 }

            },2000);

            complateCode = data.complate_code;
            complateBlock = data.complate_block;
            percentOfCourse = data.percentOfCourse;
        }else if(is_console == "1" && isConsole){
            exerciseFuncJS = data.code;
            exerciseFuncJS = decodeURI(exerciseFuncJS);
            percentOfCourse = data.percentOfCourse;
            codeUser = data.code;
             var editor = ace.edit("myEditor");
             editor.setValue(exerciseFuncJS);
            if(isFinishedBefore == 1){

                editor.setValue(exerciseFuncJS);
                $('#testButtonPlaceholder').hide();
            }

        }else{
            if(isFinishedBefore == 1){
                exerciseFuncJS = decodeURI(data.code);
                exerciseFuncCSS = decodeURI(data.css_code);
                exerciseFuncHTML = decodeURI(data.html_code);
                if(language === "js, html, css") {
                    workFuncForExPractise.displayExerciseCode(exerciseFuncJS, "javascript");
                    workFuncForExPractise.displayExerciseCodeHTML(exerciseFuncHTML);
                    workFuncForExPractise.displayExerciseCodeCSS(exerciseFuncCSS);
                }else{
                    workFuncForExPractise.displayExerciseCode(exerciseFuncJS, language);
                }
                $('#testButtonPlaceholder').hide();
            }
        }
    }
    function isFinished(data){
        if(data.is_finished_before === 1){
            if(isCanvas){
              $('#testButton').css('display', 'inline-block');
              $("#continueButton").css('display', 'inline-block');
              /*change url to next exercise of canvas course*/
              nextWindow();
            }else{
              $('#testButton').css('display', 'block');
              $("#continueButton").css('display', 'block');
              setTimeout(function(){
                  $("#continueButton").css('display', 'block');
              },500);
              if(mobile){
                  $('#mobActionButton').show();
                  $('#mobActionButton span')
                                  .removeClass('fa-ellipsis-h').addClass('fa-angle-double-right');
                  $('#mobActionButton').css('background-color','#099');
                  $('#mobActionButton').unbind( "click" );
                  $('#mobActionButton').bind('click', function(){
                      workFuncForExPractise.doActions();
                  });
              }
            }
            $('#continueButtonPlaceholder').hide();
            $("#seeAgainBtn").removeClass("inactive");
            if(typeof is_inventory != "undefined" && is_inventory == "1"){
                setTimeout(function(){
                  setTimeout(function(){shop.disable()},100);
                },500);
            }

        }else if(data.is_finished_before === 0){
            //$('#testButton').css('display', 'none');
            $("#continueButton").css('display', 'none');
            $('#continueButtonPlaceholder').show();
            $("#seeAgainBtn").addClass("inactive");
            if(typeof is_inventory != "undefined" && is_inventory == "1"){
                setGlobalVar("BROWSE_MODE",0);
            }
        }
        /*if(!isJunior && !isConsole){
            $('.finished').append("<div id='seeAgainBtn' class='inline'>"+trans('langEx.seeAgain')+"</div>");
        }*/

    }
    var finished_exercises = [];
    var next_exercise = null;
    function courseNavigation(data){
         finished_exercises = data.finished_exercises;
         next_exercise = data.activeEx;
        var apprentice = $('.nav_ex_title');
        for(var i =0;i<apprentice.length;i++){
            var id = $(apprentice[i]).attr("id");
            var is_finish = checkIsFinished(id);
            if(is_finish != false){
                $('#'+id).find('a').attr("href",baseUrl+"/exercise/view/"+is_finish)
                 $('#'+id).find('a').attr('onclick', '');
                $('#'+id).addClass('finishedSelector');
            }else if(next_exercise != null && (next_exercise.id==id || (next_exercise.number == "first" && id==next_exercise.id))){
                $('#'+id).find('a').attr("href",baseUrl+"/exercise/view/"+next_exercise.public_id)
                $('#'+id).find('a').attr('onclick', '');
                $('#'+id).addClass('finishedSelector');
            }else{
                $('#'+id).removeClass('finishedSelector');
                 $('#'+id).find('a').attr('href','#');
                 $('#'+id).find('a').attr('onclick','showMessage(this)');

            }
        }
        //
    }
    function checkIsFinished(id){
        for(var i =0;i<finished_exercises.length;i++){
            if(id==finished_exercises[i].apprenticeship_id && finished_exercises[i].finished == 1){
                return finished_exercises[i].public_id;
            }
        }
        return false;
    }
    function mobileView(data){
        if(data.isMobile == 1 && $(window).width() < 780){
            mobile = true;
        }else{
            mobile = false;
        }
        if(mobile){
            $('.mobExToggleMenu').show();
        }else {
            $('.mobExToggleMenu').hide();
        }
    }
    function userSpeedInEx(data){
        $('.speedTextHeader').empty();
        $('#speedText').empty();
        if(data.level == "easy"){
            $('.speedTextHeader').html(trans("langEx.speedNormal"));
            $('#speedText').html(trans('langEx.speedNormal')).removeClass('red yellow').addClass('green');
        }else if(data.level == "medium"){
            $('.speedTextHeader').html(trans("langEx.speedFast"));
            $('#speedText').html(trans('langEx.speedFast')).removeClass('green red').addClass('yellow');
        }else if(data.level == "hard"){
            $('.speedTextHeader').html(trans("langEx.speedSuperFast"));
            $('#speedText').html(trans('langEx.speedSuperFast')).removeClass('green yellow').addClass('red');
        }
    }
    function findActiveAprrentice(active){
        if(active.number == "first"){
            return active.ex_id;
        }else{
            return active.number;
        }

    }
    function percentOfCourseByModule(modules){
        var percent = 0;
        for(var i in modules){
            percent += modules[i];
        }
        var len = Object.keys(modules).length;
        return Math.round(percent/len);
    }
    function courseStructure(data){
        var activeEx = null;
        if(typeof data.email != "undefined"){
          activeEx = data['activeExerciseByCourse'][course_id];
        }

        var firstEx;
        if(typeof activeEx == "undefined"){
            firstEx = data['firstExerciseOfCourse'][course_id].public_id;
        }

         var coursePercent;
        if(data.course_percent != null){
              coursePercent = data.course_percent.progress;
        }


        for(var i in data.module){
            $('.mod_'+i).find('.infoBit').html(data.module[i]+"%");
        }
        var apprenticeActive;
        if(typeof data.email != "undefined" && data['activeExerciseByCourse'].length != 0){
            if(typeof activeEx != "undefined"){
            apprenticeActive = findActiveAprrentice(activeEx);
             }
        }
      if(typeof data.email != "undefined"){
        if(isJunior == "true"){
            var boxes = $('.exerciseJuniorBox');
            for(var i =0;i<boxes.length;i++){
                if(data["finish_exercises"].length != 0){
                    var isFinished = false;
                    for(var ex in data["finish_exercises"]){
                        var single_ex = data["finish_exercises"][ex].exercise_id;
                        if($(boxes[i]).attr('id') == single_ex ){
                            $(boxes[i]).removeAttr("style");
                            $(boxes[i]).addClass('passed');
                            $(boxes[i]).find('a').removeAttr('href');
                             var link = $(boxes[i]).children().clone();
                            var atag = $(boxes[i]).html('<a style="overflow:hidden; display:inline-block;width:100%;height:100%" href="'+baseUrl + '/exercise/view/'+data["finish_exercises"][ex].public_id+'"></a>');
                            $(boxes[i]).find('a').append(link);
                            if($(boxes[i]).find('.progressStatus').hasClass('fa-check')){
                                $(boxes).find('.progressStatus').attr("class","progressStatus").removeClass('fa-lock fa-unlock').addClass('fa fa-check').css({color:'#09a609'});
                                $(boxes[i]).removeAttr('onclick');
                            }else{
                                $(boxes[i]).find('.progressStatus').removeClass('fa-lock fa-unlock').addClass('fa fa-check').css({color:'#09a609'});
                                $(boxes[i]).removeAttr('onclick');
                            }
                            isFinished = true;
                            break;
                        }else if($(boxes[i]).attr('id') == data["finish_exercises"][ex].apprenticeship_id){


                        }
                    }

                    if(isFinished == false){
                        $(boxes[i]).addClass('unfinished').css({background:'#a8e0fb'});
                        $(boxes[i]).find('.progressStatus').removeClass('fa-check');
                        if($(boxes[i]).find('.progressStatus').hasClass('fa-lock')){
                            $(boxes[i]).find('.progressStatus').attr('class',"progressStatus").removeClass('fa-check fa-unlock').addClass('fa fa-lock').css({color:'#3a3a3a'});
                        }else{
                           $(boxes[i]).find('.progressStatus').removeClass('fa-check fa-unlock').addClass('fa fa-lock').css({color:'#3a3a3a'});;
                        }
                        $(boxes[i]).find('a').removeAttr('href');
                        $(boxes[i]).attr('onclick','showMessageJunior(this)');
                    }
                    if(apprenticeActive == $(boxes[i]).attr('id')){
                        $(boxes[i]).attr('onclick','');
                        var link = $(boxes[i]).children().clone();
                        var atag = $(boxes[i]).html('<a style="overflow:hidden; display:inline-block;width:100%;height:100%" href="'+baseUrl + '/exercise/view/'+data["activeExerciseByCourse"][course_id].public_id+'"></a>');
                        $(boxes[i]).find('a').append(link);
                        if($(boxes[i]).find('.progressStatus').hasClass('fa-lock')){
                            $(boxes[i]).find('.progressStatus').attr('class',"progressStatus");
                            $(boxes[i]).find('.progressStatus').addClass('fa-unlock');
                        }else if($(boxes[i]).find('.progressStatus').hasClass('fa-check')){
                           $(boxes[i]).find('.progressStatus').attr('class',"progressStatus");
                           $(boxes[i]).find('.progressStatus').addClass('fa-unlock');
                        }
                  }
                }else if(apprenticeActive == $(boxes[i]).attr('id')){
                    $(boxes[i]).find('.progressStatus').removeClass('fa-check');
                    $(boxes[i]).find('.progressStatus').removeClass('fa fa-lock');
                    $(boxes[i]).find('.progressStatus').addClass('fa-unlock');
                }else{
                    //$(boxes[i]).css({background:'#a8e0fb'});

                     $(boxes[i]).find('.progressStatus').attr('class',"progressStatus").addClass('fa fa-lock').css({color:'#3a3a3a'});
                     $(boxes[i]).addClass('unfinished').css({background:'#a8e0fb'});
                     $(boxes[i]).find('a').removeAttr('href');
                     $(boxes[i]).attr('onclick','showMessageJunior(this)');
                    //
                }
            }
            if(typeof firstEx !="undefined"){
                    $(boxes).filter(':first').find('a').attr('href',baseUrl+'/exercise/view/'+firstEx);
                    $(boxes).filter(':first').find('.progressStatus').removeClass('fa-check');
                    $(boxes).filter(':first').find('.progressStatus').removeClass('fa fa-lock');
                    $(boxes).filter(':first').attr('onclick','');
            }

            /*var  module_percent = data.module.pop();
            console.log(module_percent)*/

            if(typeof data["activeExerciseByCourse"][course_id] != "undefined" && coursePercent < 100){
                $('.heroTitle').find('a').removeAttr('href');
                $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['activeExerciseByCourse'][course_id].public_id);
                $('.startCourse').html('Продължи курса');
            }else if(coursePercent >= 100){
                $('.heroTitle').find('a').removeAttr('href');
                $(boxes).find('.progressStatus').removeClass('fa-lock').addClass('fa-check');
                $(boxes).removeAttr('onclick',"");
                $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['firstExerciseOfCourse'][course_id].public_id);
                $('.startCourse').html('Курсът е завършен');
            }else{
                $('.heroTitle').find('a').removeAttr('href');
                $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['firstExerciseOfCourse'][course_id].public_id);
                $('.startCourse').html('Започни курса');
            }
            /*if(data["activeExerciseByCourse"].hasOwnProperty(course_id)){
                $('.heroTitle').find('a').removeAttr('href');
                $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['activeExerciseByCourse'][course_id].public_id);
                $('.startCourse').html('Продължи курса');
            }else{

            }*/
        }else{
             var boxes = $('.simple-box-exercise');

            for(var i =0;i<boxes.length;i++){
                //console.log(boxes[i])
                 for(var ex in data["finish_exercises"]){
                    var single_ex = data["finish_exercises"][ex].exercise_id;


                    if($(boxes[i]).attr('id')==single_ex && single_ex != apprenticeActive){
                        var element = $('#'+single_ex);

                        if($(element).find('.innerRow span').hasClass('fa-check')){
                            $(element).find('.innerRow span').attr("class","").addClass("fa fa-check");
                        }else{
                           //$(element).find('.innerRow').html("<span class='fa fa-check'></span>");
                           //$(element).find('.exLi').append('<a href="'+baseUrl+'/exercise/view/'+data["finish_exercises"][ex].public_id+'"><div class="fa fa-check"></div></a>');
                            $(boxes[i]).find('.innerRow').removeAttr('onclick');
                            var link = $(boxes[i]).find('.innerRow').append('<div class="fa fa-check"></div>').clone();
                            var atag = $(boxes[i]).find('.tileUl').html('<a href="'+baseUrl+'/exercise/view/'+data["finish_exercises"][ex].public_id+'"></a>');
                            $(boxes[i]).find('.tileUl a').append(link);
                        }
                    }else{
                       $(boxes[i]).find('.innerRow span').remove();
                    }
                }
                //console.log("active"+apprenticeActive)
                /*if(apprenticeActive === $(boxes[i]).attr('id')){
                        //active circle
                        if($(boxes[i]).find('.innerRow span')){
                            $(boxes[i]).find('.innerRow span').attr("class","").addClass("fa fa-dot-circle-o");
                        }else{
                           $(boxes[i]).find('.innerRow').append("<span class='fa fa-dot-circle-o'></span>");
                        }
                }*/
                 if(apprenticeActive == $(boxes[i]).attr('id')){
                        //active circle
                  if($(boxes[i]).find('.innerRow span').hasClass('fa-dot-circle-o')){
                      $(boxes[i]).find('.innerRow span').attr("class","").addClass("fa fa-dot-circle-o");
                  }else{
                      $(boxes[i]).find('.innerRow').removeAttr('onclick');
                     var link = $(boxes[i]).find('.innerRow').append('<div class="fa fa-dot-circle-o"></div>').clone();
                     var atag = $(boxes[i]).find('.tileUl').html('<a href="'+baseUrl + '/exercise/view/'+data["activeExerciseByCourse"][course_id].public_id+'"></a>');
                     $(boxes[i]).find('.tileUl a').append(link);
                  }
                }
                }
        if(data["activeExerciseByCourse"].hasOwnProperty(course_id) && coursePercent < 100){
                $('.heroTitle').find('a').removeAttr('href');
                $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['activeExerciseByCourse'][course_id].public_id);
                $('.startCourse').html('Продължи курса');
        }else if(coursePercent == 100){
            $('.heroTitle').find('a').removeAttr('href');
            $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['firstExerciseOfCourse'][course_id].public_id);
            $('.startCourse').html('Курсът е завършен');
        }else{
            $('.heroTitle').find('a').removeAttr('href');
            $('.heroTitle').find('a').attr('href',baseUrl+'/exercise/view/'+data['firstExerciseOfCourse'][course_id].public_id);
            $('.startCourse').html('Започни курса');
        }
    }
  }
    }
    function isFinishedJunior(data){
        if(isJunior){
            if(mobile){
                if(data.is_finished_before == 1){
                    $('.seeAgainInGame').append('<div id="seeAgainBtnJunior">режим на разглеждане</div>');
                    $('#mobActionButton span').removeClass('fa-ellipsis-h').addClass('fa-angle-double-right').css({backgroundColor:'rgb(120, 193, 193)'});
                }else if(data.is_finished_before == 0){
                    $('.seeAgainInGame').empty();
                    // $('#mobActionButton span').removeClass('fa-angle-double-right').css({backgroundColor:'#ccc'});
                    $('#mobActionButton span').css({backgroundColor:'#ccc'});
                }
            }
        }
    }
    function showThirdStepPayment(data){

        if(data.show_payment_modal == true || data.show_modal_log == true){
            payment_method = data.payment_method;
            $('.paymentsCards').addClass("inactive");
            $('.econtDataPayment ').addClass("inactive");
            $('#bankCashTransfer').addClass("inactive");
            $('#modalStartTrial').removeClass('inactive');
            $('.continuePlan').attr("class",
            "button prColBG accColBGHoverSame tileShadowPage continuePlan   payment_last_step ");
            $('.payment_steps_global .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
            $('.payment_steps_global .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
            $('.payment_steps_global .step-3').addClass('activeStep');
            $('.continuePlan').addClass(data.payment_method);
            continuePaymentsData();
            $('.continuePlan').removeClass('premium_plan');
            $('#startTrialOverlay').removeClass('inactive');
            $('.firstStep').addClass('inactive');
            getOffices();
            $('.secondStepPremium').addClass('inactive');
        }else if(data['error-subs'] != null) {
            $('#modalStartTrial').removeClass('inactive');
            $('#startTrialOverlay').removeClass('inactive');
            $('.secondStepPremium').removeClass('inactive');
            $('.firstStep').addClass('inactive');
            $('.choosePlan').addClass('inactive');
           /* $('#emailPayFromHome').after("<p style='color:red;'>"+data['error-subs']+"</p>");*/
        }else{
            $('#modalStartTrial').addClass('inactive');
            $('#startTrialOverlay').addClass('inactive');
            $('.firstStep').removeClass('inactive');
            $('.continuePlan').removeClass('premium_plan');
            $('.thirdStepPremium').addClass('inactive');
        }

        if(data.show_login_reg_err == true){
            playNow();
            choosepaymentPlan();
        }
    }
    function checkBillUrl(){
     /*   var url = window.location.search.match(/status=true/g);

        if(url){
            $('.paid_forms').hide();
            $('.thanks_box').toggleClass('inactive');
            $('.thanks_line').append("<p>"+trans('langPage.paymentSuccess1')+" <br><span>"+trans('langPage.paymentSuccess2')+"</span></p>");
            navigationFn.goToSection('#thanks_box');
        }else if(url == false){
            $('.paid_forms').hide();
            var error_message = "Contact us";
            $('.thanks_box').toggleClass('inactive');
            $('.pay_box').css("width","80%");
            $('.thanks_line').append("<p><span>"+trans('langPage.paymentFail')+" </span></p>");
            navigationFn.goToSection('#thanks_box');
        }else{
            $('.paid_forms').show();
            $('.thanks_box').addClass('inactive');
        }*/
    }
    function progressInPaths(data){
        var isStarted = false;
        var pathBtn = $('.pathButton');

        for(var i=0;i<pathBtn.length;i++){
           var id = $(pathBtn[i]).attr('id');
           for(var all_courses in data["first_exercise_of_course"]){
                var course = data["first_exercise_of_course"][all_courses];
                for(var course_percent in data["course_percents"]){
                    var percent = data["course_percents"][course_percent];

                }
                if(data["course_percents"].hasOwnProperty(course.id)){
                    if(typeof data["activeExerciseByCourse"][course.id] != "undefined" && data["course_percents"].progress < 100){
                         $(pathBtn[i]).html(trans('langPage.continueCourse'));
                    }else if(data["course_percents"].progress == 100){
                         $(pathBtn[i]).html(trans('langPage.finishedCourse'));
                         $(pathBtn[i]).parent().attr('href',"");
                    }else{
                        $(pathBtn[i]).html(trans('langPage.startCourse'));
                    }
                }else{
                    $(pathBtn[i]).html(trans('langPage.startCourse'));
                }

            }
           if(Object.keys(data["active_exercise_by_course"]).length >0 ){
               for(var activeEx in data["active_exercise_by_course"]){
                    var active = data["active_exercise_by_course"][activeEx];
                    if("pathBtn"+active.id == id){
                        for(var course_percent in data["course_percents"]){
                            var percent = data["course_percents"][course_percent];
                            /*var percent = data["course_percents"][active.id];*/
                            if(data["course_percents"].hasOwnProperty(active.cours_id)){
                                if(percent.progress < 100){

                                     $(pathBtn[i]).html(trans('langPage.continueCourse'));
                                     $(pathBtn[i]).parent().attr('href',baseUrl+"/exercise/view/"+active.public_id);
                                     $(pathBtn[i]).parent().attr("onclick","");
                                     $('.courseTile'+active.cours_id).find('.startCourseButton').attr('href',baseUrl+"/exercise/view/"+active.public_id);
                                     $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.continueCourse'));
                                     $('.courseTile'+active.cours_id).find('.profileCoursePercent').html(percent.progress + "%");
                                     $('.courseTile'+active.cours_id).find('.profileProgressFront').css('width',percent.progress + "%");
                                }else{
                                     $(pathBtn[i]).html(trans('langPage.finishedCourse'));
                                     $(pathBtn[i]).parent().attr('href',"");
                                     $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.finishedCourse'));
                                      $('.courseTile'+active.cours_id).find('.profileCoursePercent').html(percent.progress + "%");
                                      $('.courseTile'+active.cours_id).find('.profileProgressFront').css('width',percent.progress + "%");

                                }
                            }
                        }

                        isStarted = true;
                    }else{
                        for(var firstEx in data["first_exercise_of_course"]){
                            var first = data["first_exercise_of_course"][firstEx];
                            if("pathBtn"+first.cours_id == id && !isStarted){
                                if(typeof data['course_percents'][first.cours_id] != "undefined" &&  data['course_percents'][first.cours_id].progress == 100){
                                    $(pathBtn[i]).html(trans('langPage.finishedCourse'));
                                      $(pathBtn[i]).parent().attr("onclick","");
                                    $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.finishedCourse'));
                                     $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.finishedCourse'));
                                      $('.courseTile'+active.cours_id).find('.profileCoursePercent').html(percent.progress + "%");
                                      $('.courseTile'+active.cours_id).find('.profileProgressFront').css('width',percent.progress + "%");

                                }else{
                                    $(pathBtn[i]).html(trans('langPage.startCourse'));
                                    $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                                    $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                                    $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                                    $('.courseTile'+active.cours_id).find('.profileCoursePercent').html("0%");
                                    $('.courseTile'+active.cours_id).find('.profileProgressFront').css('width', "0%");
                                //$(pathBtn[i]).parent().attr('href',baseUrl+"/exercise/view/"+first.public_id);
                                }

                            }

                        }
                    }
                 }
           }else{
               for(var firstEx in data["first_exercise_of_course"]){
                    var first = data["first_exercise_of_course"][firstEx];
                    if("pathBtn"+first.cours_id == id){
                        $(pathBtn[i]).html(trans('langPage.startCourse'));
                        if(typeof active != "undefined"){
                            $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                            $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                            $('.courseTile'+active.cours_id).find('.startCourseButton div').html(trans('langPage.startCourse'));
                            $('.courseTile'+active.cours_id).find('.profileCoursePercent').html("0%");
                            $('.courseTile'+active.cours_id).find('.profileProgressFront').css('width', "0%");
                        }
                        //$(pathBtn[i]).parent().attr('href',baseUrl+"/exercise/view/"+first.public_id);
                    }

                }


           }
                /*console.log("tuk");*/


        }
    }
    function progressInPathsRefactored(data){
        var pathBtn = $('.pathButton');
        $('.pathButton').parent().removeAttr('href');
        $('.pathButton').parent().removeAttr('onclick');
        console.log(data);
        console.log(pathBtn);
        for(var i=0;i<pathBtn.length;i++){
          var course_id = $(pathBtn[i]).attr('id').split('pathBtn')[1];

          var course_progress =typeof data.course_percents[course_id] != "undefined"?data.course_percents[course_id]:false;
          console.log(course_progress["progress"]);
         if(!course_progress || course_progress["progress"] == 0){

                $(pathBtn[i]).html(trans('langPage.startCourse'));
                $(pathBtn[i]).parent().attr('onclick','gotoExercise("'+data.first_exercise_of_course[course_id]["public_id"]+'",this)')


          }else if(course_progress && course_progress["progress"] > 0 && course_progress["progress"] < 100){
              if(typeof data.active_exercise_by_course[course_id] != "undefined"){
                  $(pathBtn[i]).html(trans('langPage.continueCourse'));
                  $(pathBtn[i]).parent().attr('href',baseUrl+'/exercise/view/'+data.active_exercise_by_course[course_id]["public_id"]);
              }else{
                  $(pathBtn[i]).html(trans('langPage.continueCourse'));
                  $(pathBtn[i]).parent().attr('href',baseUrl+'/exercise/view/'+data.first_exercise_of_course[course_id]["public_id"]);
              }



          }else if(course_progress && course_progress["progress"] >= 100){
            $(pathBtn[i]).html(trans('langPage.finishedCourse'));
             $(pathBtn[i]).parent().attr('href',baseUrl+'/exercise/view/'+data.first_exercise_of_course[course_id]["public_id"]);
          }
        }
    }
    function parentsPage(data){

        if(is_user){
            var name;
            var firstName;
            if(data.username){
                name = (data.username).split(' ');
                firstName = name[0];
            }else{
                firstName = data.email;
            }
            $('#username .textColor').empty();
            if(data.role == "student"){
                $('.userStatus').html(trans("langPage.roleStudent"));
                $('#toNewCourse').remove();
            }else if(data.role == "teacher"){
                $('.userStatus').html(trans("langPage.roleTeacher"));

            }
            $('#username .textColor').html(firstName);

            if(data.avatar == false){
                $('#user_icons').empty();
                $('#user_icons').html('<span class="fa fa-user"></span>');
            }else{
                $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
            }
            $('#toLogin').remove();
            $('#toRegister').remove();

        }
    }
    function showStopSite(data){
        var time_stopping = 0;

        if(data.time_stop != null){
           if(typeof data["time_stop"] != "undefined"  && typeof data["time_stop"].data_stopping != "undefined"){
            time_stopping = data["time_stop"].data_stopping;
            }
            console.log(time_stopping);
        }
        console.log(time_stopping);
        /*if(data["time_stop"].data-stopping){

        }*/
        var dateT = Math.round(+new Date(time_stopping * 1000)/1000);
        console.log(dateT);
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var time  = new Date().getTime();

        var unix = Math.round(+new Date()/1000);
        console.log(unix);
        if(/*dateT > unix &&*/ time_stopping < time && localStorage.getItem("next_time") == null && time_stopping != 0){
            $('.stopping-site').show();
            $('.stopping-site-overlay').show();
            $('.stopping-site').find('.msg-text').html(data["time_stop"].msg_stopping);

        }
        $('.ok-stop').click(function(){
            localStorage.setItem("next_time",tomorrow);
            $('.stopping-site').hide();
            $('.stopping-site-overlay').hide();
        });
        if(time > localStorage.getItem("next_time") && time<time_stopping && time_stopping != 0){
            $('.stopping-site').show();
            $('.stopping-site-overlay').show();
        }
       /* console.log(data["time_stop"].data_stopping);

        console.log(tomorrow);
        console.log(time);
        if(time > tomorrow && time < data["time_stop"].data_stopping){

        }*/
    }
    function toCodeEditorCache(){
        if(isCanvas){
            editorMode = 'code';
        }
        $('#blocklyTestButton').hide();
        $('#blocklyJuniorContainer').hide();
        $('.blocklyWidgetDiv').hide();
        $('.blocklyTooltipDiv').hide();
        $('.blocklyToolboxDiv').hide();
        $('#toCodeEditor').removeClass('inactiveEditor').css('cursor', 'default');
        $('#toBlockEditor').addClass('inactiveEditor').css('cursor', 'pointer');
        $('#aceTestButton').css('display', 'inline-block');
         stopCanvas();
         resetPosition();
        $('#hintsBottomBlock').addClass('inactive').slideUp();
        $('#testButtonPlaceholder').hide();
        /* if(!first_load_editor){*/
            try{
                initJuniorEditor();
            }catch(ex){
                console.log(ex);
                setTimeout(function(){ initJuniorEditor();},1000);
            }

            first_load_editor = true;
        /*}*/


        workFuncForExPractise.extendJuniorAvatar(false);
        desktopChangeJuniorModeToCode(true);
        isBlockly = false;
        reloadGame(false);
        if(showRightAnswer){
            $("#desktoRightBlock").css('display','none');
            $("#desktoRightCode").css('display','block').show();
        }



        $('#editorCont').css({display:'inline-block',marginTop:'0',float:"left"});

        if(!$('#runTwiceFastBlock').hasClass('inactive')){
            $('#runTwiceFastBlock').addClass('inactive');
            $('#runTwiceFastCode').removeClass('inactive');
        }
        $('#inst-theory-block').addClass('inactive');
        $('#inst-theory-code').removeClass('inactive');
    }
    function toBlockEditorCache(){
         if(isCanvas){
             editorMode = 'block';
        }
        /*$('.chooseRedactorType').hide();
        $('.chooseRedactorTypeOverlay').hide();*/
        $('#toBlockEditor').removeClass('inactiveEditor').css('cursor', 'default');
        $('#toCodeEditor').addClass('inactiveEditor').css('cursor', 'pointer');
        $('#aceTestButton').hide();
        $('#editorCont').hide();
        $('#blocklyJuniorContainer').fadeIn();
        $('.blocklyWidgetDiv').fadeIn();
        $('.blocklyTooltipDiv').fadeIn();
        $('.blocklyToolboxDiv').fadeIn();
        $('#blocklyTestButton').css('display', 'inline-block');
        stopCanvas();
        resetPosition();
        //reloadGame(false);
        $('#hintsBottomCode').addClass('inactive').slideUp();
        $('#testButtonPlaceholder').hide();


        workFuncForExPractise.extendJuniorAvatar(false);
        $('#inst-theory-block').removeClass('inactive');
        $('#inst-theory-code').addClass('inactive');
        isBlockly = true;
        reloadGame(false);
        if(showRightAnswer){
            $("#desktoRightBlock").css('display','block').show();
            $("#desktoRightCode").css('display','none');
        }


        if(!$('#runTwiceFastCode').hasClass('inactive')){
            $('#runtTwiceFastBlock').removeClass('inactive');
            $('#runTwiceFastCode').addClass('inactive');
        }
         refreshBlockly();
    }
    function renderFacebookNav(data){
        $('#game_levels').html("");
        var exercises;
        var count = 1;

        for(var ex in data["exercise_from_course"]){
            exercises = data["exercise_from_course"][ex];
            if(exercises.level == "A" /*&& exercises.lesson_id == exercises.id*/){
                var is_finish = null;
                for(var finished in data["finished_exercises"]){
                    if(data["finished_exercises"][finished].id == exercises.id){
                        is_finish = true;
                    }
                }
                if(data["aprenticeFromSeriesId"] == exercises.id || exercises.id == exerciseId){
                        $('#game_levels').append('<li id="'+exercises.id+'" class="nav_ex_game nav_ex_game_finished">'+
                        '<span class="ex_counter">'+count+'</span>'+
                        '<a href="'+baseUrl+'/exercise/view/'+exercises.public_id+'"></a>'+
                        '<div class="stars_container">'+
                            '<span class="stars_collected"></span>'+
                           ' <span class="stars_collected"></span>'+
                            '<span class="stars_collected"></span>'+
                        '</div>'+
                    '</li>');
                }   else if(is_finish){
                    $('#game_levels').append('<li id="'+exercises.id+'" class="nav_ex_game nav_ex_game_finished_checked">'+
                        '<a href="'+baseUrl+'/exercise/view/'+exercises.public_id+'"></a>'+
                        '<div class="stars_container">'+
                            '<span class="stars_collected_finished"></span>'+
                           ' <span class="stars_collected_finished"></span>'+
                            '<span class="stars_collected_finished"></span>'+
                        '</div>'+
                    '</li>');
                }else if(is_finish == false && data["active_apprenticeship"] == exercise.id){
                    $('#game_levels').append('<li id="'+exercise.id+'" class="nav_ex_game  nav_ex_game_finished">'+
                        '<span class="ex_counter">'+counter+'</span>'+
                        '<a href="'+baseUrl+'/exercise/view/'+exercises.public_id+'"></a>'+
                    '</li>');

                }else if(is_finish == null){
                    $('#game_levels').append('<li id="'+exercises.id+'" class="nav_ex_game nav_ex_game_locked" onclick="unfinishedExerciseMsg(this)"><span class="ex_lock"></span><a  href="#" ></a></li>');
                }
                count++;
            }
        }
    }

    function multiplayerPanel(data){
        /*if(!data.multiplayer_available){
            $('#exCount').html(data.count_to_multiplayer);
            $('.remainingEx').html("Оставащи упражнения");
            $('#inviteFriends').hide();
            $('#randomFriends').hide();
        }else{
             $('#exCount').html('<span class="fa fa-check"></span>');
             $('.remainingEx').html("Готов за multiplayer");
             $('#randomFriends').show();
             $('#inviteFriends').show();
        }
        */
       if(data.allowMultiplayer == 1){
            $('.multiplayerPlanel').removeClass('inactive');
             $('.switchEx').css({display:'inline-block'});
            $('.remainingII').find('#exCnt').html(data.count_to_multiplayer);
            if(data.friendsCount < 2){
                $('.remainingII').find('.frCnt').html(1 - data.friendsCount);
                 $('.remainingIII').find('.frCnt').html(2- data.friendsCount);
            }else{
                 $('.remainingII').find('.frCnt').html(0);
                 $('.remainingIII').find('.frCnt').html(0);
            }
            var level1 = false;
            var level2 = false;
            var levelAward = false;

            //var a = true;

            if(data.friendsCount < 3){
                $('.remainingAward').find('.frCnt').html(3 - data.friendsCount);
            }else{
                  $('.remainingAward').find('.frCnt').html(0);
            }

            if(data.multiplayer_points < 10){
                $('.remainingAward').find('#scoreCnt').html(10 - data.multiplayer_points);
            }

            if(data.friendsCount >=1){
                $('.multiplayer2').find('.meter span').css({width:'100%'});
                $('.remainingII').addClass('inactive');
                level1 = true;
            }
            if(data.friendsCount >=2){
                $('.multiplayer3').find('.meter span').css({width:'100%'});
                $('.remainingIII').addClass('inactive');
                level2 = true;
                level1 = false;
            }
            if(data.score > 10 && data.friendsCount >=3){
                $('.remainingAward').addClass('inactive');
                levelAward = true;
                level2 = false;
                level1 = false;
            }
            if(level1){
               $('.remainingIII').removeClass('inactive');
            }else if(level2){
                $('.remainingAward').removeClass('inactive');
            }

            if(free_course_first_ex == data.activeEx.public_id){
               $('#freeCourseMultiplayer').fadeIn();
               $('#freeCourseMultiplayerModal').fadeIn();



            }
            $('#score').html(data.score);
        }
        if(free_course_first_ex == public_id){
                $('#onboardingModal').fadeIn();
              $('#onboardingOverlay').fadeIn();
           }
    }
    function removeFreeCourseBanner(data){
        if(data.is_free_course_finished){
            $('.freeCourseBgProgramming').hide();
            $('.freeCourseBg').hide();
        }else{
            $('.freeCourseBgProgramming').show();
            $('.freeCourseBg').show();
        }
    }
    function assignmentCourses(data){
        var percents = data.course_percents;
        for(var percent in percents){
          if(percents[percent].progress>=100 && $('#course-'+percent).length != 0){
            $('#course-'+percent).find('.courseAssignments .assignStart div').removeClass('disabledBtn');
          }
        }
    }
    function endFreeScreens(data){
        if(data.freeScreenEnd && data.demo_sequence){
          $('.freeScreensEndModal').fadeIn();
          $('.freeScreensEndOverlay').fadeIn();
          var time = 5;
          var redirectUrl = "";

          if(courseId == free_course_id){
            redirectUrl = "/exercise/view/"+console_course_first_ex;
          }

          if(courseId == console_course_id){
            redirectUrl = "/exercise/view/"+web_course_first_ex;
          }

          if(courseId == web_course_id){
            redirectUrl = "/exercise/view/"+web1_course_first_ex;
          }

          if(courseId == web1_course_id){
            redirectUrl = "/end-demo";
          }
          $('.freeScreensEndModal').find('img').attr('src',$('.freeScreensEndModal').find('img').attr('data-src'));
          var redirectInterval = setInterval(function(){
            $('#redirectingTime').html(time);
            if(time == 0){
              clearInterval(redirectInterval);
              window.location.href=baseUrl+redirectUrl;
            }
            time--;
          },1000);
        }
    }
    CacheProvider.prototype.home = function(){
        get("cache/home",{},function(data){


            avatarData(data);
            coursesPercent(data);
            token(data);
            errorMsgInHome(data);
            showThirdStepPayment(data);

            showDiscountDialog(data);
            console.log(params);
            $.get(baseUrl+"/"+"cache/send-report-emails",params)
            .done(function( data ){
                console.log("success");
            });

        });
    };
    CacheProvider.prototype.courses = function(){
        get("cache/courses",{},function(data){

            avatarData(data);
            coursesPercent(data);
            removeFreeCourseBanner(data);
        });
    };
    CacheProvider.prototype.courses = function(){
        get("cache/programiranezadeca",{},function(data){

            avatarData(data);
            coursesPercent(data);
            removeFreeCourseBanner(data);
        });
    };
    CacheProvider.prototype.paths = function(){
        get("cache/paths",{},function(data){
            avatarData(data);
            coursesPercent(data);
        });
    };
     CacheProvider.prototype.pathsStructure = function(){
        get("cache/path/"+id,{},function(data){
            avatarData(data);
            showUserSchoolCourse(data.school_courses,data.role);
            /*progressInPaths(data);*/
            progressInPathsRefactored(data);
            coursesPercentInPaths(data);
            /*courseStructure(data);*/
            assignmentCourses(data);
        });
    };
    CacheProvider.prototype.courseStructure = function(){
        get("cache/course/"+id,{},function(data){
            avatarData(data);
            courseStructure(data);
        });
    };
    CacheProvider.prototype.bill = function(){
        get("cache/bill",{},function(data){
             avatarData(data);
             token(data);
             checkBillUrl();
             errorMsgInHome(data);
            showThirdStepPayment(data);
            showDiscountDialog(data);
        });
    };
    CacheProvider.prototype.register = function(){
        get("cache/register",{},function(data){
            token(data);
            errorMsgRegister(data);

        });
    };
     CacheProvider.prototype.login = function(){
        get("cache/login",{},function(data){
            token(data);
            errorMsg(data);
        });
    };
    CacheProvider.prototype.profile = function(){
        get("cache/profile",{},function(data){
            avatarData(data);
            coursesPercent(data);
            profileAvatar(data);
            token(data);
        });
    };
    function isSubscribe(data){
        if(typeof data.is_subscribe != "undefined" && data.is_subscribe != null){
         $('.subscribeWrapper').find('.justifyFlexAround').text("Ти вече се абонира за бюлетина!").css('color','#777');
        }else if(typeof data.is_subscribe != "undefined" &&
                typeof data.is_subscribe_queue != "undefined" && data.is_subscribe == null && data.is_subscribe_queue == "1"){
             $('.subscribeWrapper')
                     .find('.justifyFlexAround')
                     .text("Изпратихме ви и-мейл за потвърждение.")
                     .css('color','#777');
        }

    }
    CacheProvider.prototype.exercise = function(){
        get("exercise/params/" +id ,{},function(data){
            /*gameLoaded();*/
            if(data.is_access === false){
                window.location.href = baseUrl;
            }
            redirectIfBonusNotExist(data);
            avatarInEx(data);
            exHeader(data);
            if(isJunior){
                assignVariablesJunior(data);
            }else{
                assignVariables(data);
            }

            userCode(data);
            isFinished(data);
            setExToken(data);
            courseNavigation(data);
            userSpeedInEx(data);
            mobileView(data);
            isFinishedJunior(data);
            firstEnterEx(data);

            if(!isJunior){
                if(!mobile){
                  assertPageIsLoaded();
                }
            }
            //render iframe
            if (!mobile && !isJunior) {
                setTimeout(function(){workFuncForExPractise.renderCodeToIFrame("yes");},500);
            }else if(mobile && !isJunior){
                setTimeout(function(){workFuncForExPractise.renderCodeToIFrame("yes");},500);
            }
            if(isJunior){
                changeUserEditor(data);
            }

            if(!isJunior){
              if(language == "js, html, css"){
                    clickTab2(".editorTab", "activeTab", "inactiveTab", $('#htmlEditor_Tab'), ".workBox");
              }
            }
            if(is_embed == "1"){
                renderFacebookNav(data);
            }

            if(!isJunior){
                showDarkTheme(data);
            }
            if(isJunior){
                multiplayerPanel(data);
            }

              endFreeScreens(data);

        });
    };
    function showDarkTheme(data){
        /*if(data.color == -1){
            setTimeout(function(){
                var htmlEditor = ace.edit("htmlEditor");
                var cssEditor = ace.edit("cssEditor");
                var myEditor = ace.edit("myEditor");
                $('#themeDark').remove();
                htmlEditor.setTheme("ace/theme/dawn");
                cssEditor.setTheme("ace/theme/dawn");
                myEditor.setTheme("ace/theme/dawn");

            },500);
        }else{
            $.get(baseUrl + "/css/themeDark.css", function (data) {

                $('head').append('<style id="themeDark">' + data + '</style>');

            });
        }*/
        changeEditorTheme = data.color;
        if(data.color == -1){
            $.get(baseUrl + "/css/themeDark.css", function (data) {

                $('head').append('<style id="themeDark" class="darkTheme">' + data + '</style>');

            });
        }else{
             $('.darkTheme').remove();
             setTimeout(function(){
                var htmlEditor = ace.edit("htmlEditor");
                var cssEditor = ace.edit("cssEditor");
                var myEditor = ace.edit("myEditor");
                /*$('#themeDark').remove();*/
                htmlEditor.setTheme("ace/theme/dawn");
                cssEditor.setTheme("ace/theme/dawn");
                myEditor.setTheme("ace/theme/dawn");

            },1000);
        }

    }
    function redirectIfBonusNotExist(data){
        if(typeof data.is_bonus != "undefined"  && data.is_bonus != null){
           // window.location.href=baseUrl+'/profile/show?bonus_msg='+data.is_bonus;
        }
        is_paid = data.is_payment;
        if(typeof data.show_share_modal != "undefined" && data.show_share_modal == true){
            showPromotionDialog();
        }
        if(typeof data.show_end_free_course != "undefined" && data.show_end_free_course < exerciseId){
           $('#promotion_overlay').remove();
            $('#promotion_modal').remove();
        }else if(typeof data.url_share != "undefined" && data.url_share == 1 && data.show_end_free_course != exerciseId){
            $('#promotion_overlay').remove();
            $('#promotion_modal').remove();
        }

    }
    CacheProvider.prototype.endCourse = function(){
        get("/cache/endCourse" ,{id:courseId},function(data){
            avatarData(data);

            if(data.certificate != null){
                $('#certificate').html("<a class='certificate_placeholder_color' onclick='Certificate.getHTML("+data.certificate+")'></a>");
            }else{
                $('#certificate').html("Все още не можеш да получиш своя сертификат!");
            }

            if(data.assign_public_id != "" && data.assign_public_id != null){
              $('#assignments').removeClass('inactive');
              $('#assignments').find('a').attr('href',baseUrl+'/exercise/view/'+data.assign_public_id.public_id);
            }
        });
    };
     CacheProvider.prototype.epayBill = function(){
        get("/cache/bill" ,[],function(data){
            avatarData(data);

        });
    };
    CacheProvider.prototype.schoolCoursePages = function(){
        get("/cache/schoolCoursePages" ,[],function(data){
            avatarData(data);

        });
    };
    CacheProvider.prototype.parents = function(){
        get("/cache/parents",[],function(data){
            parentsPage(data);
            showDiscountDialog(data);
        });
    }

    function showUserSchoolCourse(data,role){
        for(var i in data){
            $('.courseTile'+data[i].course_id).show();

            $('.courseList'+data[i].course_id).show();
            if(current_page == "path"){
                 $('#course-'+data[i].course_id).show();
            }
        }
        if(role == "admin"){
            $('.courseTile').show();
            $('.coursePathList').show();
        }
    }

    function showDiscountDialog(data){
        amount_discount = data['amount'];
        half_amount_discount = data["half_amount_discount"];
        course_amount_discount = data["course_amount_discount"];
        third_amount_discount = data["third_amount_discount"];
        console.log(amount_discount);
        addPromotion();
        if(data.discount == true){

            if(data.share_promotion == 1 && data.discount_percent == 0){
                /*$('.discount-window').removeClass("inactive");
                $('.d_percent').html(5);
                $('.promotion-price span').html(amount_discount);*/
            }else{
                if(data.discount_percent == 10 || data.discount_percent == 100){
                    $('.discount-window').removeClass("inactive");
                }
                $('.payment_plan_half').find('.smallPlan').html('<span class="price-underlined price-darker">'+half_amount+'лв. </span> ' + half_amount_discount + 'лв.');
                $('.payment_plan_premium').find('.smallPlan').html('<span class="price-underlined price-darker">'+amount+'лв. </span> ' + amount_discount + 'лв.');
                if(data.discount_percent == 0){
                    $('.d_percent').html(5);
                }else{
                    /*$('.d_percent').html(data.discount_percent);*/
                    if(data.discount_percent == 10){
                        $('.d_percent').html(20);
                    }else if(data.discount_percent == 100){
                        $('.d_percent').html(amount-amount_discount);
                    }

                }

                $('.promotion-price span').html(amount_discount);
            }
        }

        if(data.share_url == 1){
             $('.share-left').find('.checkmark-circle').css({display:'inline-block'});
        }
    }
    function clickTab2(allTabClass, activeClass, inactiveClass, el, allDivClass) {
        var id = $(el).attr("id");
        id = id.replace("_Tab", "");

        $(el).removeClass(inactiveClass).addClass(activeClass);
        $(allTabClass).not(el).removeClass(activeClass).addClass(inactiveClass);

        $("#" + id).removeClass('inactive');

        $(allDivClass).not("#" + id).addClass('inactive');
        /*  var tab_editor = ace.edit(activeEditor);
          tab_editor.resize();*/

    }
    function getStyle(){
      console.log("get style");
    }
    function getStyleRuleValue(style, selector, sheet) {
        var sheets = typeof sheet !== 'undefined' ? [sheet] : document.styleSheets;
        for (var i = 0, l = sheets.length; i < l; i++) {
            var sheet = sheets[i];
            if( !sheet.cssRules ) { continue; }
            for (var j = 0, k = sheet.cssRules.length; j < k; j++) {
                var rule = sheet.cssRules[j];
                if (rule.selectorText && rule.selectorText.split(',').indexOf(selector) !== -1) {
                    return rule.style[style];
                }
            }
        }
        return null;
    }
    return CacheProvider;
})();
var cp = new CacheProvider();
