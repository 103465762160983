//en
var tklangEx = {
    
    /* ---- MOTIVATIONAL MESSAGES ---- */
    msg0: "Congratulations, you are the fastest student of the month on this exercise!",
    msg1: "Congratulations, you are the fastest student of the week on this exercise!",
    msg2: "Congratulations, you are the second fastest student of the month on this exercise!",
    msg3: "Congratulations, you are the fastest student for the day on this exercise!",
    msg4: "Congratulations, you are the second fastest student of the week on this exercise!",
    msg5: "Congratulations, you are the third fastest student of the month on this exercise!",
    msg6: "Congratulations, you are the fastest student for the day on this exercise!",
    msg7: "Congratulations, you are the second fastest student of the week on this exercise!",
    msg8: "Congratulations, you are the third fastest student of the month on this exercise!",
    msg9: "Congratulations, you are the third fastest student of the week on this exercise!",
    msg10: "Congratulations, you are the second fastest student of the day on this exercise!",
    msg11: "Congratulations, you are the third fastest student of the day on this exercise!",
    msg12: "Great! You completed the exercise with no hints! ",
    msg13: "Great! You completed the exercise on your first try!",
    
    viewRightAnswer: "Look at the right answer and when you are ready go ahead!",
    completeGoalMsg: 'You completed your goal!',
    goalGreet: 'Congratulations! You did your goal for the day!',
    chooseNewGoal: 'Choose new goals for this course!',
    daily_goal : 'Дневна цел',
    goal_no_from_course:'Този курс не е от дневната цел!',
    change_goal_profile:'Смени целта в профила си!',
    divTitle: 'Choose goals',
    shrinkMSG: 'Shrink Panel',
    expandMSG:'Expand Panel',
    mcTestRight: "This is the right answer, but Why!? :)",
    mcTestWrong: "This is a wrong answer, but Why!?",
    
    correctTest: "Correct!",
    wrongTest: "Wrong result!",
    
    missingLangHeading: "Course not translated",
    missingLangText: "Unfotunately this course is not translated in your language. It will start in English.",
    unfinishedHeading: "Незавършен курс",
    unfinishedText: "За съжалие този курс не е завършен.",
    notYourLanguageText: "Unfotunately this course is not translated in your language.",
    speedNormal: "Normal",
    speedFast: "Fast",
    speedSuperFast: "Super Fast",
    sureSpeedNormal: "Are you sure you want to go normal speed?",
    sureSpeedFast: "Are you sure you want to go fast speed?",
    sureSpeеdSuperFast: "Are you sure you want to go super fast speed?",
    blockMove:'С това блокче се придвижваме на напред',
    blockJump:'this block jump',
    blockSuperJump:'superJump',
    blockStepUp:'stepUp',
    blockStepDown:'stepDown',
    blockPush:'push',
    blockPull:'pull',
    blockTurn:'turn',
    blockGet:'get',
    blockDrop:'drop',
    blockBoat:'move boat',
    blockSetActive:'set active',
    blockTurnto:'turn to',
    blockPlayer:'player',
    blockNear:'near',
    blockSee:'see',
    blockLadder:'on ladder',
    blockWithDo:'with do',
    blockgGoto:'goto',
    blockIs:'is',
    editorsRescrict:"Ограничението за брой редове е ",
    blockly_msg: "Please, select new call block",
     buy_label_restiction:"Трябва да закупиш ",
    bow:"Арбалет",
    sword:"Meч",
    shield:"Броня",
    magic_jump:"Магически скок",
    no_ruby_for_sword:"Нямате достатъчно рубуни за закупуване на меч!",
    no_ruby_for_bow:"Нямате достатъчно рубуни за закупуване на арбалет!",
    no_coin_magic_jump:"Нямате достатъчно монети за закупуване на магически скок!",
    no_coin_for_shield:"Нямате достатъчно монети за закупуване на броня!",
     continue:'Мини напред'
};

