var edited_phone;
$(document).ready(function(){
    var user_phone = "359";
    if(registration_type){
        $('#registerButton').click(function(){
            user_phone="359"+$('#register_phone').val();
            if( $('#emailInput').val() != "" && $('#register_password').val() != "" && $('#register_phone').val() != ""){
                if(user_phone.match(/^[0-9]{12}$/g) == null){
                     $('.errorMsg ul').html('<span>Невалиден номер!</span>');
                     console.log(user_phone);
                     return;
                }
                if($('#register_password').val().length < 6){
                     $('.errorMsg ul').html('<span>Паролата трябва да повече от 6 символа!</span>');
                     return;
                }
                if(!validateEmail($('#emailInput').val())){
                    $('.errorMsg ul').html('<span>Невалиден формат на email!</span>');
                    return;
                }
                if(!$('#agreeCheckBox').is(":checked")){
                    $('.errorMsg ul').html('<span>Моля съгласете се с условията!</span>');
                    return;
                }
                if(grecaptcha.getResponse() == ""){
                    $('.errorMsg ul').html('<span>Попълнете кепчата!</span>');
                    return;
                }
                $('.errorMsg ul').html('');
                if(sessionStorage.getItem("captcha-expired") != null){
                    if(sessionStorage.getItem("modal") == "sms"){
                        $('.smsApproveModal').fadeIn();
                        $('.smsApproveOverlay').fadeIn();
                    }else if(sessionStorage.getItem("modal") == "tel"){
                        $('.telApproveModal').fadeIn();
                        $('.telApproveOverlay').fadeIn();
                    }
                    sessionStorage.removeItem("modal");
                    sessionStorage.removeItem("captcha-expired");
                }else{
                    $('.telApproveModal').fadeIn();
                    $('.telApproveOverlay').fadeIn();
                }
                $('#telNumber').val($('#register_phone').val());
                $('.telUser').html('0'+$('#register_phone').val());
            }else{
                $('.errorMsg ul').html('<span>Въведете всички полета!</span>');
            }
        });
    }
    $('#sendSmsCode').click(function(){
        if($('#telCont').is(":visible")){
            edited_phone = "359"+$('#telNumber').val();
            if(edited_phone.match(/^[0-9]{12}$/g) == null){
                $('.errSms').html('<span>Невалиден номер!</span>');
                return;
           }
            if($('#telNumber').val() != ""){
                
                $('#register_phone').val($('#telNumber').val());
                $('.telUser').html('0'+$('#register_phone').val());
            }
        }
        
        /*$('.loading-register').show();*/
        sendSmsCode(function(){
             $('.smsApproveModal').fadeIn();
             $('.smsApproveOverlay').fadeIn();
             $('.telApproveModal').fadeOut();
             $('.telApproveOverlay').fadeOut();

        });
    });
    $('#editTel').click(function(){
        $('#telCont').show().css({display:'block'});
    });
    var countSmsTries = 0;
    $('#submitSmsCode').click(function(){
        countSmsTries++;
         var smsValue = $('#smsCode').val();
        $('#sms_code').val(smsValue);
        if(countSmsTries <= 3){
            $.get(baseUrl+"/sms/checkCode",
               {
                    code:smsValue
               } )
               .done(function( data ) {
                   if(data.result == "OK"){
                        $('#registrationForm').submit();
                   }else{
                       $('.errCode').html("Неправилен код");
                   }

               });
        }else{
            $('.errCode').html("Прекалено много опити");
        }
    });
    
    $('.closeSmsModal').click(function(){
        $('.sureLeaveReg').fadeIn();
    });
    $('#sureExit').click(function(){
        $('.smsApproveModal').fadeOut();
        $('.smsApproveOverlay').fadeOut();
        $('.telApproveModal').fadeOut();
        $('.telApproveOverlay').fadeOut();
        $('.sureLeaveReg').fadeOut();
    });
    $('#denyExit').click(function(){
        $('.sureLeaveReg').fadeOut();
    });
});


function sendSmsCode(callback){
    $.get(baseUrl+"/sms/send",
    {
         phone:"359"+$('#register_phone').val(),
         'g-recaptcha-response':grecaptcha.getResponse()
    } )
    .done(function( data ) {
        if(data.status && data.result.search('OK')>=0 ){
            callback();
            $('#telUser').html('+'+$('#register_phone').val());
        }else if(typeof data.msg != "undefined"){
            $('.loading-register').hide();
            $('.errorMsg ul').html('<span>'+data.msg+'</span>');
            $('.errSms').html('<span>'+data.msg+'</span>');
            /*grecaptcha.reset();*/
        }else if(typeof data.result != "undefined" && data.result.search('OK')< 0){
            $('.loading-register').hide();
            $('.errorMsg ul').html('<span>Проблем с изпращането на sms</span>');
             $('.errSms').html('<span>'+data.msg+'</span>');
            /*grecaptcha.reset();*/
        }else if(typeof data.captcha != "undefined"){
             $('.smsApproveModal').fadeOut();
             $('.smsApproveOverlay').fadeOut();
             $('.telApproveModal').fadeOut();
             $('.telApproveOverlay').fadeOut();
        }
    });
}
var sms = {
    phone:null,
    sendSmsCodeFromProfile:function(){
        
        if($('#userPhone').val().match(/^\+[0-9]{12}$/g) == null){
            $('.errSms').html('<span>Невалиден номер!</span>');
            return;
        }
        sms.phone = $('#userPhone').val().replace('+','');
        $('#savePhoneNumber').html('Изпращане...');
    $.get(baseUrl+"/sms/send-user",
    {
         phone:sms.phone
    } )
    .done(function( data ) {
        if(data.status && data.result.search('OK')>=0 ){
            $('.sendSms').show();
            $('.sendSmsHead').hide();
            $('#telUser').html($('#userPhone').val());
            $('#userPhone').hide();
            $('#savePhoneNumber').html('Потвърди');
            $('#savePhoneNumber').attr('onclick','sms.validateAndSaveNumber()');
            $('#userSmsCode').show();
        }else if(typeof data.msg != "undefined"){
            $('.loading-register').hide();
            $('.errSms').html('<span>'+data.msg+'</span>');
            $('#savePhoneNumber').html('Изпрати');
        }else if(typeof data.result != "undefined" && data.result.search('OK')< 0){
            $('.loading-register').hide();
            $('.errSms').html('<span>Проблем с изпращането на sms</span>');
            $('#savePhoneNumber').html('Изпрати');
        }
    });
    },
    
    validateAndSaveNumber:function(){
        $('#userSmsCode').removeClass('wrongInput');
        $('.errSms').html('');
         $('#savePhoneNumber').html('Потвърдждаване...');
        ajaxPostRequester('user/put/telephone',{phone:sms.phone,code:$('#userSmsCode').val()},function(data){
          if(typeof data.error != "undefined"){
               $('.errSms').html(data.error);
            $('#userSmsCode').addClass('wrongInput');

            $('#savePhoneNumber').html("Потвърди");


          }else{
            $('#phoneSuccess').html('<span style="color:green">Успешно добавен телефон!</span>');
            setTimeout(function(){
                $('.askingForTelephone').fadeOut();
                $('.askingForTelephoneOverlay').fadeOut();
            },2000);
          }
        });
         
    }
};