var baseUrl = null;
function getBaseUrl(){
   if(window.location.hostname == "localhost"){

    baseUrl = window.location.protocol + '//'+ window.location.hostname+ "/lara5.5/public";
    }else{

        baseUrl = window.location.protocol + '//'+ window.location.hostname;
    }
}
getBaseUrl();
var ltiDomain = "int.eltargovia.info";
var lastEditorChange = 0, lastExFunctionCode = "", lastExFunctionCSS = "", lastExFunctionHTML = "";
window['js_func'] = [];
var teacherAvatarOpen = false;
var isSystemRow = false;

/*var complateCode = 0;
var complateBlock = 0;*/
/*
 * Editors
 */
var exerciseId;

var activeEditor;
var activeEditorName;
var isViewRightCode = false;

var isProfile = false;
var htmlEditor, cssEditor, htmlKeyboard, cssKeyboard, myKeyboard;

var url, dbURL = baseUrl + "/action",
     defaultFuncJS, testFunc, defaultFuncHTML, defaultFuncCSS;

/* variables with MC test code */
var mcCodeJS, mcCodeHTML, mcCodeCSS, inputOutputFunc, inputOutputFuncHTML, inputOutputFuncCSS;

var exerciseFuncHTML, exerciseFuncCSS, exerciseFuncJS;
var exerciseTestFunc;
var consoleOutput = null;
var  isLoaded = false;
//var exerciseId = 'undefined',
      exerciseLevel = 'undefined',
      userJuniorCode="undefined",
      userJuniorHTML = "undefined";

function parseJSON(data) {
    return window.JSON && window.JSON.parse ? window.JSON.parse( data ) : (new Function("return " + data))();
}
var sessionValArr = parseJSON(localStorage.getItem("sessionValArr")) || {};

var mobile;

var editor;
var defaultJSCodeEditor,
        defaultHTMLCodeEditor,
        defaultCSSCodeEditor,
        TestFuncEditor;
var isConsole = false;
var is_compiler = false;
var equationVariables = [];
var lastAdaptivityId = 0, lastRuleId = 0;
var ruleIdArr = "";
var rulesCounter = 0,
        localRuleCounter = 0;
var lastRule = false, lastCondition = false, lastAction = false;
var actionCounter = [];
var editCondCountArr = [];

var percentsForThisProfession, firstRender = true;

/*function load defer javascript*/

function loadJS(path) {
    getBaseUrl();

    let version = (window.App && window.App.asset_version) || (new Date).getTime();

    if (!path.includes('v=')) {
        path += (path.includes('?') ? '&' : '?') + 'v=' + version;
    }

    var element = document.createElement("script");
    element.setAttribute("type","text/javascript");
    element.setAttribute("charset","utf-8");
    element.src = baseUrl+path;
    document.body.appendChild(element);
}

function loadCapcha(){
    var element = document.createElement("script");
    element.setAttribute("type","text/javascript");
    element.src = 'https://www.google.com/recaptcha/api.js?hl='+locale;
    document.body.appendChild(element);
}

function loadJSWithoutExecution(path){
    var o = document.createElement('object');
    o.data = baseUrl+path;
    document.body.appendChild(o);
    document.getElementsByTagName("object")[0].style.display  = "none";
}
