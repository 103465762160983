var gdpr = {

  	updatePaymentInfo:function(){

  		var payment_data = $('#updatePaymentInfo');
  		payment_data = getFormData(payment_data);
  		console.log(payment_data);

  		$.ajaxSetup({
	        headers: {
	            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	        }
        });
        $.post(baseUrl+"/user/updatePaymentInfo",{data:payment_data} )
        .done(function( data ) {
              console.log(data);
               	
        });
  	},
    updatePaymentInfoEcont:function(){
      var payment_data = $('#updatePaymentInfoEcont');
      payment_data = getFormData(payment_data);
      console.log(payment_data);

      $.ajaxSetup({
          headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });
        $.post(baseUrl+"/user/updatePaymentInfoEcont",{data:payment_data} )
        .done(function( data ) {
              console.log(data);
                
        });
    },
    updatePersonalInfo:function(){
        var personal_data = $('#updatePersonalInfo');
        personal_data = getFormData(personal_data);
        $.ajaxSetup({
          headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });
        $.post(baseUrl+"/user/updatePersonalInfo",{data:personal_data} )
        .done(function( data ) {
            var data = data.msg;
            if(data != "ok"){
               for(var i in data){
                $('#updatePersonalInfoErrors').append(data[i]);
               }
            }
                
        });
    },
    exportUserInformation:function(){

        $.get(baseUrl+'/user/exportUserInformation')
        .done(function(data){
          console.log(data);
        })
    },
    openConfirmDelete:function(){
      $('#confirmDeleteModal').show();
      $('#confirmDeleteOverlay').show();
    },
    yesDelete:function(){
      $('.deleteBtns').hide();
      $('.deletePass').show();
    },
    noDelete:function(){
       $('#confirmDeleteModal').hide();
      $('#confirmDeleteOverlay').hide();
    },
    deleteAccount:function(){
      var pass = $('#password').val();
       $.ajaxSetup({
          headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });
      $.post(baseUrl+"/user/deleteRequest",{pass:pass} )
        .done(function( data ) {
            if(data == "error"){
              $('#deletePass').append("Грешна парола");
            }else if(data == "ok"){
              $.post(baseUrl+"/user/deleteUserAccount",{pass:pass} )
              .done(function(data){
                if(data == "ok"){
                  window.location.href=baseUrl+"/home";
                }
                
              }); 
            }
      });
    }

}
function getFormData($form){
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function(n, i){
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}