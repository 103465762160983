var Certificate = {
    getHTML:function(id,callback){
        $.get(baseUrl+'/certificate/get/html/'+id,function(data){
            
            $('#certificate-template').html(data);
            Certificate.open()
            if(typeof callback != "undefined"){
                callback();
            }
        });
    },
    open:function(){
        $('.certificate-modal').show();
        $('.certificate-overlay').show();
    },
    close:function(){
        $('.certificate-modal').hide();
        $('.certificate-overlay').hide();
    },
    uploadPhoto:function(id){
        console.log(id)
        /*console.log($('.img-load'));*/
       /* $.ajaxSetup({
       headers: {
           'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
       }
       });
     $.post(baseUrl+"/upload-cert-image", 
        {
           image:$()
        } )
        .done(function( data ) {

             
        }); */
        
        if(user_cert_name == ""){
            Certificate.getHTML(id);
            setTimeout(function(){
                $('.please_enter_name').show();
                $('.please_enter_name_overlay').show();
                setTimeout(function(){
                    $('.please_enter_name').fadeOut();
                    $('.please_enter_name_overlay').fadeOut();
                },3000);
                /*Certificate.getName();*/
               $('.certificate_wrapper #fullname').attr('onkeypress','Certificate.getName()');
            },2000);
        }else{
           Certificate.getHTML(id,function(){
               Certificate.share(id);
           });
           ;
        }
    },
    share:function(id){
        Certificate.load();
         /*setTimeout(function(){*/
            var cert_canvas;
            html2canvas($("#certificate-template"), {
                onrendered: function(canvas) {

                    theCanvas = canvas;
                    cert_canvas = canvas;


                     var data1 = canvas.toDataURL('image/png');

                    Canvas2Image.convertToPNG(canvas,1029,757); 
                    /*Canvas2Image.convertToPNG(canvas,1200,630);*/
                    

                     $.ajax({
                       url: baseUrl+"/upload-cert-image",
                       headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                       type: 'post',
                       data: {image:data1,name:$('#fullname').val()},
                       success:function(data){
                            var url = data;
                            Certificate.closeLoad();
                           // setTimeout(function(){
                                shareCertificate(data["name"]);
                           // },2000);

                       }
                   });
                }
            });
            /*},2000);*/
    },
    
   getName:function(id){
      var fullname = $('.certificate_wrapper #fullname').val();

        if(fullname.trim().length > 3){
            //init share btn
            $('.certificate-modal .badge_share').css({display:'block',margin:'0 auto'});
            $('.certificate-modal .badge_share').attr('onclick','Certificate.share('+id+')')
            console.log("B");
        }
      
   },
   
   load:function(){
       $('.download-loader').show();
      // $('.download-loader').css('left','43%');
   },
   closeLoad:function(){
       $('.download-loader').hide();
      // $('.download-loader').css('left','50%');
   }
};