//en
var tklangPageEx = {
    /* Profile Ticket Validation */
    mandatoryFields: 'Enter your message, please!',
    sendProblem: 'Problem with sending!',
    thankYouMsg: 'Thank you! A specialist will contact you on your e-mail address.',
    goalCompletedTick: 'Goal completed!',
    goalNotCompletedTick: 'Previous modules not completed!',
    ticketStatus: 'Status:',
    statusOpen:'Open'
};

