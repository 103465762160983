var FCM = (function(){
    
    var historyArray = [];
   
    function FCM(){}
    function NotificationProvider(publish_key){
      
    }
    FCM.prototype.getHistory = function(){
        return historyArray;
    }
    function getRandomArbitrary(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }
    /*
     * Subscribe for channel
     */
    function syncUserRegistrationId(id){
        if(localStorage.getItem("registrationId") != id){
            $.get(baseUrl+"/notification/sync/registration/"+id, function( data ) {
               console.log('is sync');
               localStorage.setItem("registrationId",id)
            });
        }
    }
    FCM.prototype.listen = function(){
         var config = {
            apiKey: "AIzaSyC3AegCialyrmeeNymfrn1sj657hBNZVwM",
            authDomain: "push-noti-166012.firebaseapp.com",
            databaseURL: "https://push-noti-166012.firebaseio.com",
            projectId: "push-noti-166012",
            storageBucket: "push-noti-166012.appspot.com",
            messagingSenderId: "915537903740"
          };
          firebase.initializeApp(config);

        var messaging = firebase.messaging();
          navigator.serviceWorker.register(baseUrl+"/firebase-messaging-sw.js")
        .then(function(registration){
          messaging.useServiceWorker(registration);
          
          messaging.requestPermission()
        .then(function() {
          console.log('Notification permission granted.');
          return messaging.getToken();
        }).then(function(token) {
          console.log(token);
          registrationId = token;
          syncUserRegistrationId(token);
        })
        .catch(function(err) {
          console.log('Unable to get permission to notify.', err);
        });

        messaging.onMessage(function(payload) {
          //console.log("Message received. ", payload);
          var noty = new BrowserNotification();
          /*fire noty*/
          noty.fire(
                payload.notification.title,
                payload.notification.body,
                getRandomArbitrary(1,10000000),
                payload.notification.click_action
          );
        });
          
        });
        
    }
    /*
     * Pushing notification in pubnub
     */
    FCM.prototype.push = function(title,massage,url){
          alert('imlement push');
    }
    FCM.prototype.history = function(limit){
        if(Notification.permission === "granted" || Notification.permission == "default"){
            limit = (typeof limit !== 'undefined') ?  b : 3;
            pubnub.history(
                {
                    channel : channel,
                    count : limit,

                },
                function(status, response){
                    historyArray = status;
                    var noty = new BrowserNotification();
                    if (0 in status){
                        for(var i in status[0]){
                            if(typeof status[0][i] != "undefined")
                                noty.fire(status[0][i].text,status[0][i].title,status[0][i].id,status[0][i].url);
                        }
                    }
                }
            );
        }
    }
    
    
    
    return FCM;
})();
function showNotificationquenstion(){
   $('#notificationPopUp').show(); 
}
function closeNotiPopUp(){
    $('#notificationPopUp').hide();
    Notification.requestPermission(function(result) {
        if (result === 'granted') {
            fcm = new FCM();
            fcm.listen()
        }
    });
}
/**
 * Starting PubNub browser notification 
 * @type NotificationProvider
 */
var fcm;
try{
    if(Notification.permission == "granted"){
        
            fcm = new FCM(); 
            fcm.listen();
       
    }else if(Notification.permission == "default"){
        setTimeout(function(){
        /*showNotificationquenstion();*/
        closeNotiPopUp();
        },60000);
    }
    
}catch(ex){
    console.log(ex);
}

function getMobileDeveices(){
    $.ajaxSetup({
       headers: {
           'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
       }
       });
    $.get(baseUrl+"/api/mobile_devices", {} )
    .done(function( data ) {

        sendNotification("Разгледайте новия ни курс",$('#course_name').text());

    }); 
}
function sendNotification(title,message){
    
    var payload = { 
        to: localStorage.getItem('registrationId'),
  
        data: {
          image: "http://example.com/cool-image.jpg",
          title: title, 
          body: message,
          priority: 2,
          "content-available": "1",
          "force-start": "1"
        } 
    }
     $.ajaxSetup({
    headers: {
        'Content-Type': 'application/json',
        'Authorization':'key=AAAAw1rtAQc:APA91bHFQtyjvwtjZojysI5DopirrD_CoUpP7fM1n5CdUkaGfsUM1kQqZrbrYZexeaRNBzMAZm1ce9PG4DuT7qtMZ6ZCfGP1B85MhVeI_H0MJdWSrMZTGDmkK8zhm7xj8Cglswo0Wgih'
    }
    });
    $.post("https://gcm-http.googleapis.com/gcm/send",JSON.stringify(payload) )
    .done(function( data ) {
            


    });
}