/**
*@param  amount in cents
*/
function openStripeDialog(amount, currency, name, description, publicKey, plan){
    $('#plan-field').attr("value", plan);
    var token = function(res){
        var $input = $('<input type=hidden name=stripeToken />').val(res.id);
       
        
        $('#pay-form').append($input).submit();
    };
    $('.payment-btn').addClass("inactive");
    $('.payment-loading').removeClass("inactive");
   
   
    StripeCheckout.open({
        key:         publicKey,
        amount:      total(amount),
        description: description,
        name: name,
        alipay: true,
        locale: "auto",
        email:       user_email,
        currency:    currency,
        panelLabel:  'Checkout',
        image:      'https://stripe.com/img/documentation/checkout/marketplace.png',
        token:       token
    });
  
    return false;
};



$('.get_plan_account').click(function(){
    
     showPayForm();
    /*$('#select-type-payment').hide();
    
    if(user_email != "null"){
        $('.overlayDark').show();
        $('#restriction_modal').show();
    }else{
        $('.paid_message').html(trans('langPage.sign_require'))
    }*/
    
    
});
function showStripe(){
   
    if(user_email != "null"){
          var url;
    var account_per_month = rates[0].amount * 100;
    var account_per_year = rates[1].amount * 100;
     var company_per_month = rates[4].amount * 100;
    var company_per_year = rates[5].amount * 100;

    if(!$('#companyAccount').hasClass("inactive")){
        if(!$('#pay_price_per_month_company').hasClass("inactive")){
           
            openStripeDialog(company_per_month, 'USD', 'Account','pay for month', publicKey,"pay_price_per_month_company");
        }
        else if(!$('#pay_price_per_year_company').hasClass("inactive")){
         
          openStripeDialog(company_per_year, 'USD', 'Account','pay for year', publicKey,"pay_price_per_year_company");
        }
    }
    else {
        if(!$('#pay_price_per_month_personal').hasClass("inactive")){
           
            openStripeDialog(account_per_month, 'USD', 'Account','pay for month', publicKey, "pay_price_per_month_personal");
        }
        else if(!$('#pay_price_per_year_personal').hasClass("inactive")){
          
          openStripeDialog(account_per_year, 'USD', 'Account','pay for year', publicKey,"pay_price_per_year_personal");
        }
    }  
    }else{
        
    }
}
function showCheckout(){
   
    if(user_email != "null"){
          var url;
    var account_per_month;
    var account_per_year;
    var company_per_month;
    var company_per_year;
    if(locale == "bg"){
         account_per_month = rates[6].public_id ;
         account_per_year = rates[7].public_id ;
         company_per_month = rates[8].public_id ;
         company_per_year = rates[9].public_id ;
    }else{
         account_per_month = rates[0].public_id;
        account_per_year = rates[1].public_id;
        company_per_month = rates[4].public_id;
        company_per_year = rates[5].public_id;  
    }      
    redirectToCheckout(account_per_year);

    /*if(!$('#companyAccount').hasClass("inactive")){
        if(!$('#pay_price_per_month_company').hasClass("inactive")){
           
           
            redirectToCheckout(company_per_month);
        }
        else if(!$('#pay_price_per_year_company').hasClass("inactive")){
         
         
           redirectToCheckout(company_per_year);
        }
    }
    else {
        if(!$('#pay_price_per_month_personal').hasClass("inactive")){
                redirectToCheckout(account_per_month);
        }
        else if(!$('#pay_price_per_year_personal').hasClass("inactive")){
           redirectToCheckout(account_per_year);
        }
    }  */
    }else{
        
    }
}

function redirectToCheckout(plan){
    
    $('#adyen_form').remove();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
    $.post(baseUrl+"/checkout/paid", 
    {
        plan:plan,
        num_users:1,
        
    })
    .done(function( data ) {
        $('body').append(data);

    }); 
}
function gotoEpay(invoice){
  if(user_email != "null"){
          var url;
    var account_per_month = rates[6] ;
    var account_per_year = rates[7] ;
     var company_per_month = rates[8] ;
    var company_per_year = rates[9] ;

       if(invoice == "account" || invoice == "card"){
              
            EpayProformaInvice(account_per_year.public_id, 1,$('#per_year_personal'), invoice);
       }else if(invoice == 'invoice'){
            //get invoice number
            epayInvoiceCode(account_per_year.public_id)
        }else if(invoice == 'order-cash'){

           window.location.replace(baseUrl+"/order/cash/"+account_per_year.public_id);
        }else if(invoice == 'order-bank'){
            window.location.replace(baseUrl+"/order/bank/"+account_per_year.public_id);

        }
    }else{
        
    }  
}
$('#get_plan_api').click(function(){
    $('#select-type-payment').hide();
     if(user_email != "null"){
    var url;
    var number_of_users = $("#people_count").val();
    var with_br_price = rates[2].amount * 100;
    var with_out_br_price = rates[3].amount * 100;
    //console.log(number_of_users * $('#tariff_with_branding'))
    if($('#tariff_with_branding').attr('class').split()[0] === 'pay_price'){
        $('#num-user-field').attr("value", number_of_users);
        openStripeDialog(with_br_price * number_of_users, 'USD', 'API','With Branding', publicKey, "tariff_with_branding");

      
    }

    if($('#tariff_without_branding').attr('class').split()[0] === 'pay_price'){
        $('#num-user-field').attr("value", number_of_users);
        openStripeDialog(with_out_br_price * number_of_users, 'USD', 'API','Without Branding', publicKey, "tariff_without_branding");

       
    }
     }else{
         //show message for register
     }
});


var navigationFn = {
    goToSection: function(id,top) {
        if(typeof top != "undefined"){
            $('html, body').animate({
                scrollTop: $(id).offset().top-top
            }, 400);
        }else{
            $('html, body').animate({
                scrollTop: $(id).offset().top
            }, 400);
        }
        
    }
}

   
  function clickSwitch() {
        $('.switch_top').toggleClass('switch_position');
        
        if($("#personalAccount").hasClass("inactive")) {
            //person
            $("#companyAccount").addClass("inactive");
            $("#personalAccount").removeClass("inactive");
            
            $("#toCompanyAccount").removeClass("pc_on");
            $("#toPersonalAccount").addClass("pc_on");            
        }
        else {
            //to company
            $("#personalAccount").addClass("inactive");
            $("#companyAccount").removeClass("inactive");
            
            $("#toPersonalAccount").removeClass("pc_on");
            $("#toCompanyAccount").addClass("pc_on");     
        }
    }
    
    $('.per_time').click(function(){
        if($(this).hasClass("toYear")) {
            $(".pay_price_per_year").removeClass("inactive");
            $(".pay_price_per_month").addClass("inactive");
        }
        else {
            $(".pay_price_per_month").removeClass("inactive");
            $(".pay_price_per_year").addClass("inactive");
        }
        
        $(".per_time").removeClass("per_on");
        $(this).addClass("per_on");
    });
    
    $('.enroll_now').click(function(){
        $(this).parent('api_box').toggleClass('inactive');       
    });
    
    $(".changeBr").click(function() {
        var id = $(this).attr("id");
        var with_br_dollar = rates[2];
        var with_out_dollar = rates[3];
        var with_br_lev = rates[10];
        var with_out_lev = rates[11];
        if(locale == "bg"){
            $("#tariff_with_branding").text(with_br_lev.amount* $("#people_count").val()+"лв.");
            $("#tariff_without_branding").text(with_out_lev.amount * $("#people_count").val()+"лв.");
        }else{
            $("#tariff_with_branding").text('$' + with_br_dollar.amount * $("#people_count").val());
         $("#tariff_without_branding").text('$' + with_out_dollar.amount * $("#people_count").val());
        }
         
        if(id === "withoutBr") {
            $("#tariff_with_branding").addClass("inactive");
            $("#tariff_without_branding").removeClass("inactive");
        }
        else {
            $("#tariff_without_branding").addClass("inactive");
            $("#tariff_with_branding").removeClass("inactive");
        }
    });
    
    $('.api').click(function(){
        api($(this));
    });
    
    function api($this) {
        var id = $this.attr("id");
        
        switch (id) {
            case "toApi": 
                $("#api").removeClass('inactive');
                $("#account").addClass("inactive");
                $("#toAccount").removeClass('inactive');
                $("#toApi").addClass("inactive");
                
                $(".switch_wrapper").html("");
                
                break;
            case "toAccount": 
                if($("#account").hasClass('inactive')){
                    $("#account").removeClass('inactive');
                    $("#api").addClass("inactive");
                    $("#toApi").removeClass('inactive');
                    $("#toAccount").addClass("inactive");
                    $("#companyAccount").addClass("inactive");
                    $("#personalAccount").removeClass("inactive");
                    $(".switch_wrapper").html("<div id='toPersonalAccount' class='plan pc_on personal'>Personal account</div>"
                    + "<div class='switch_button pointer click_function' id='switch_button' onclick='clickSwitch()'>"
                        + "<div class='switch_base'>"
                            + "<div class='switch_top'></div>"
                        + "</div>"
                    + "</div>"
                    + "<div id='toCompanyAccount' class='plan'>Company account</div>");
                }
                
                //todo
                /*$('#switch_button').click(function(){
                    clickSwitch();
                });*/
                break;
        }       
    };
 

function selectBtn(){
    $('#select-type-payment').hide();
    $('#easy-pay-form').show();
    $('#back-type-btn').removeClass('inactive');
    
     /*select correct fields*/
    var type = $('#selectTypeData').val();
    if(type == "EGN"){
        $('.company').addClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').removeClass("inactive");
    }else if(type == "LNC"){
        $('.company').addClass("inactive");
        $('.lnc').removeClass("inactive");
        $('.person').addClass("inactive");
    }else if(type == "BULSTAT"){
        $('.company').removeClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').addClass("inactive");
        $('#eik_dds').hide();
        $('.dds_label').hide();
        
        $('#easy-pay-fullname').attr("placeholder","МОЛ");
        $('.name_label').text("МОЛ");
    }else if(type == "VAT"){
        $('.company').removeClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').addClass("inactive");
        $('#eik_dds').show();
        $('.dds_label').show();
        $('#easy-pay-fullname').attr("placeholder","МОЛ");
        $('.name_label').text("МОЛ");
    }
}
function backBtn(){
     $('#select-type-payment').show();
    $('#easy-pay-form').hide();
    $('#back-type-btn').addClass('inactive');
}
function getPersonInfo(){
    var person_name = $('#easy-pay-fullname').val();
    var person_type_id = $('#selectTypeData').val();
    var person_id = null;
    if(person_type_id == "VAT" || person_type_id == "BULSTAT"){
         person_id = $('#BULSTAT').val();
    }else{
        person_id = $('#'+person_type_id).val();
    }
   
    var adress = $('#address').val();
    var company_name = $('#company_name').val();
    return {
        person_name: $('#easy-pay-fullname').val(),
        person_type_id: $('#selectTypeData').val(),
        person_id: person_id,
        address: $('#address').val(),
        company_name: $('#company_name').val(),
        eik_dds: $('#eik_dds').val()
    };
}
function epayInvoiceCode(plan){
        $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
        $.post(baseUrl+"/bill/epay/invoice", 
        {rate_id:plan,
           
        } )
        .done(function( data ) {
            if(data.search("ERR:") == 0){
                $('#easy-pay-form').find('h4').html(data.split("ERR:")[1]);
               
            }else{
             $('#epayPaid').prop('action',baseUrl+"/bill/easypay");
             $('#p-data').prop('value',data);
             $('#epayPaid').submit();
            }
          
             
        }); 
   
    
       
}

function postPaymentinfo(){
    var info = getPersonInfo();
    var person_name = info.person_name;
    var person_type_id = info.person_type_id;
    var person_id = info.person_id;
    var address = info.address;
    var company_name = info.company_name;
    var eik_dds = info.eik_dds;
    
        $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
        $.post(baseUrl+"/bill/post/paymentinfo", 
        {
            person_name: person_name,
            person_number: person_id,
            number_type: person_type_id,
            address: address,
            company_name: company_name,
            eik_dds: info.eik_dds
        } )
        .done(function( data ) {
           
             if(data.search("OK") >= 0 ){
               showMethods();
                
                 
             }else{
                 $('#easy-pay-form').find('h4').html(data);
             }
             
        }); 
}
function showMethods(){
   $('.payment-btn').show();
   $('#easy-pay-form').hide();
   $('#back-type-btn').hide();
}

$("input[name=select-type]:radio").change(function () {
    var type = $('input[name=select-type]').filter(":checked").val();
    $('#selectTypeData').val(type);
    /*if(type == "EGN"){
        $('.company').addClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').removeClass("inactive");
    }else if(type == "LNC"){
        $('.company').addClass("inactive");
        $('.lnc').removeClass("inactive");
        $('.person').addClass("inactive");
    }else if(type == "BULSTAT"){
        $('.company').removeClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').addClass("inactive");
        $('#eik_dds').hide();
    }else if(type == "VAT"){
        $('.company').removeClass("inactive");
        $('.lnc').addClass("inactive");
        $('.person').addClass("inactive");
        $('#eik_dds').show();
    }*/
});



function changeTypeInvoice(self){
    console.log(self);
    var id = $(self).attr("id");
    var select = $(self).find(":selected").val();
    if(select == "company"){
        $('.person_'+id).addClass('inactive');
        $('.company_'+id).removeClass('inactive');
    }else if(select == "person"){
        $('.person_'+id).removeClass('inactive');
        $('.company_'+id).addClass('inactive');
    }
}

/*function toggleInvoice(){
    $('#invoices').toggle("slow");
}*/
function EpayProformaInvice(plan, num_users, form, type){
    $('#epayPaid').prop('action',baseUrl+"/bill/micro"); 
    if(type == "account"){
        $('#epayPaid').prop('action',baseUrl+"/bill/micro"); 
        $('#epay-type').prop('value',type); 
    }else if(type == "card"){
         $('#epay-type').prop('value',type); 
    }
    $('#epayPaid').find('#plan').prop('value', plan);
    $('#epayPaid').submit();
     /*$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
    $.post(baseUrl+"/bill/post/proforma", {rate_id:plan, num_users:num_users,type:type} )
    .done(function( data ) {
        console.log(data);
        if(data != "ERR"){
            data = JSON.parse(data);
        
            $('body').append(data.form);
            $('#'+data.form_id).submit();
        }
        
    });*/
}


function showEasyPayForm(){
    $('#select-type-payment').show();
    $('#payinfo-btn-submit').hide();
    $('#easy-pay-btn-submit').removeClass("inactive");
    //$('#easy-pay-form').removeClass("inactive");
    
   
}
function showPayForm(){
   
    //$('#easy-pay-form').removeClass("inactive");
    $('.payment-btn').addClass("inactive");
    $('#payinfo-btn-submit').removeClass("inactive");
     $('.overlayDark').show();
    $('#restriction_modal').show();
   
}

function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}
$('#closeRestrictionModal').click(function(){
   $('#restriction_modal').hide(); 
   $('.overlayDark').hide();
});
$("#billsTitle").parent().click(function() {
    
        if (!$("#invoices").is(":visible")) {
            console.log('aaa');
            $("#invoices").slideDown();
            $("#billsAngle span").removeClass("fa-angle-down").addClass("fa-angle-up");
        } else {
            $("#invoices").slideUp();
            $("#billsAngle span").removeClass("fa-angle-up").addClass("fa-angle-down");
        }
}); 

$("#messageTitle").parent().click(function() {
    
        if (!$("#user-message").is(":visible")) {
            console.log('aaa');
            $("#user-message").slideDown();
            $("#billsAngle span").removeClass("fa-angle-down").addClass("fa-angle-up");
            changeMessageStatus();
        } else {
            $("#user-message").slideUp();
            $("#billsAngle span").removeClass("fa-angle-up").addClass("fa-angle-down");
        }
}); 
function changeMessageStatus(user)
{
    if($('#msg-counter').text() > 0){
    //if( $('.msgCount ').hasClass("newMessage")){
        $.get(baseUrl+"/message/status/"+user,function( data ) {
            $('.msgCount ').removeClass('newMessage');
            $('.msgCount ').text(0);
        }); 
    }
    
}

function sendAnswer(message_id){
    var answer = $('#answer-'+message_id).val();
    console.log(answer);
    if(answer  != ""){+
        $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
        $.post(baseUrl+"/message/sendAnswer",{id:message_id, text:answer},function( data ) {
            $('#list-answers-'+message_id).append("<p class='answer'>"+answer+"</p>");
        }); 
    }
}

$("#certificatesTitle").parent().click(function() {
    
        if (!$("#certificates").is(":visible")) {
            
            $("#certificates").slideDown();
            $("#certificatesAngle span").removeClass("fa-angle-down").addClass("fa-angle-up");
        } else {
            $("#certificates").slideUp();
            $("#certificatesAngle span").removeClass("fa-angle-up").addClass("fa-angle-down");
        }
});
    
