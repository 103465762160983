//en
var tklangPage = { 
    hideAcord: 'Hide content',
    showAcord: 'Show all content',
    messageChange: 'Change the password',
    messageNoChange: "Don't change the password",
    invalidMail: 'Type in a correct e-mail!',
    successfulSubscribe: 'Thanks for the subscribtion!',
    messageCon: 'You need to finish the previous excersises first',
    ticketStatus: 'Status:',
      statusOpen: 'Open',
    statusClose: 'Close',
    paymentSuccess1: 'Thank you for your purchase!',
    paymentSuccess2: 'Your payment was successful.',
    paymentFail: 'Your payment was Failed.',
    sign_require: "Трбява да влезете като потребител за да продължите",
     startCourse:"Start course",
    continueCourse:"Continue course",
    finishedCourse:"Course finished"
};

