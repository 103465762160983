
var BrowserNotification = (function(){
    function BrowserNotification(){
        listen();
        
    }
    /*
     * Add event for listen for notification is fire
     * @returns {undefined}
     */
    function listen(){
        
            document.addEventListener('DOMContentLoaded', function () {
                if (!Notification) {
                  console.log('Desktop notifications not available in your browser. Try Chromium.'); 
                  return;
                }

              
            });
              
      
    }
    /**
     * Show browser notification
     * @param {type} title
     * @param {type} message
     * @returns {undefined}
     */
    BrowserNotification.prototype.fire= function (title,message,id,url){
         url = (typeof url!="undefined")?url:"http://localhost/azprogramiram-scale/public/";
          /**
           * show if user not  seen notification
           */  
        if(!this.isSeen(id)){
            if(checkDevice() == "Android"){
                /*worker notification for Android device*/
                showNotyWithWorkers(title,message,id,url);
            }else{
                /*custom notification for desktop Firefox,chrome, Edge*/
                
                if (!("Notification" in window)) {
                    console.log("This browser does not support desktop notification");
                  }

                  // Let's check whether notification permissions have already been granted
                  else if (Notification.permission === "granted") {
                    // If it's okay let's create a notification
                    showBasicNoty(title,message,id,url);
                  }else if (Notification.permission !== "denied") {
                    
                       showBasicNoty(title,message,id,url);
                      
                  };
            }
            
              
            this.markAsSeen(id); 
          }
          
        }
    
    function checkDevice(){
        if (navigator.userAgent.match(/iPad/i))
        {
            return 'iPad'
        } else if(navigator.userAgent.match(/webOS|iPhone|iPod|Blackberry/i) )
        {
            return 'iPhone';
        } 
         else if(navigator.userAgent.match(/Android/i) ){
             return 'Android';
         }else if(navigator.userAgent.match(/Windows Phone/i)){
              return 'WP';
         }
         else {
            return 'desktop';
        }
    }
    function showNotyWithWorkers(title,message,id,url){
        navigator.serviceWorker.register(baseUrl+'/sw-noty.js');
              //Notification.requestPermission(function(result) {
                if (result === 'granted') {
                  navigator.serviceWorker.ready.then(function(registration) {
                     
                    registration.showNotification(title, {
                      body: message,
                      icon: baseUrl+'/data/launcher-icon-4x.png',
                      vibrate: [200, 100, 200, 100, 200, 100, 200],
                      data:[
                          {action:url}
                      ]
                    
                    });
                    
                
                  });
                }
             // });
    }
   

  

    function showBasicNoty(title,message,id,url){
        
         var notification = new Notification("</PROGRAMiRAM.com>", {
            icon: baseUrl+'/data/launcher-icon-4x.png',
            body: message,
          });

          notification.onclick = function () {
            window.open(url);      
          };
    }
    
    
    BrowserNotification.prototype.markAsSeen = function(id){
        localStorage.setItem(id, true);
    }
    
    BrowserNotification.prototype.isSeen = function(id){
        var is_seen = localStorage.getItem(id);
        if(is_seen == "true"){
            return true;
        }
        return false;
    }
    
    return BrowserNotification;
})();

/**
 * Ask user for allow notification 
 */
try{
   /*if (Notification.permission != "granted" && Notification.permission != "denied"){
       Notification.requestPermission();
   }*/
}catch(err){
    
}
