var mail =  {
    sendNewConfirmEmail:function(){
       $('#emailAprroveMsg').html("");
       /*$('.loader_send_mail').show();*/
        $('#sendNewConfirmEmailBtn').html("Изпращане...");
       $.get(baseUrl+"/email/sendNewConfirmEmail", function( data ) {
            $('#emailAprroveMsg').html(data.status).css({color:'green'});
            /*$('.loader_send_mail').hide();*/
            $('#sendNewConfirmEmailBtn').html("Изпрати");
       });
    }
}